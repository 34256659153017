import {axiosInstance} from '@/helpers/axiosInstance'
import router from '@/router'
import {dispatch} from "v-tables-3/compiled/mixins/methods";

const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
}
export default {
    async login({commit, dispatch}, data) {
        try {
            return await axiosInstance.post('/api/v1/auth/login', data, {
                headers,
            }).then(async res => {
                if (res.data.message === "success"){
                    let token = res.data.result.token.accessToken
                    // // SET TOKEN
                    sessionStorage.setItem('accessToken', token)
                    sessionStorage.setItem('isLogin', '1')
                    // save info by getMe
                    await dispatch('getMe',token);
                    await dispatch('getSecretStripe',token);
                }
                return res
            }).catch(error => {
                return false
            })
        } catch (error) {
            console.log(error)
            dispatch('showNotification', {
                title: 'Error',
                type: 'error',
                text: 'Login fail, please try again !',
            })
            return false
        }
    },
    async getMe({commit},token) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
            return await axiosInstance.get('/api/v1/auth/me', {headers})
                .then(async response => {
                    let result = response.data.result
                    sessionStorage.setItem('isLogin', '1')
                    sessionStorage.setItem('me', JSON.stringify(result))
                    // set icon language
                    switch(result.region_code) {
                        case 'USD':
                        case 'EURO':
                            localStorage.setItem('i18n_locale','en')
                            break;
                        case 'VN':
                            localStorage.setItem('i18n_locale','vi')
                            break;
                        case 'AUD':
                            localStorage.setItem('i18n_locale','aud')
                            break;
                        default:
                        // code block
                    }
                    commit('SET_ME',JSON.stringify(result))
                    return response
                })
                .catch(error => {
                    console.log(error)
                    return false
                })
        } catch (error) {
            console.log(error)
            return false
        }
    },
    async setLocateLanguage(code){
        console.log('setLocateLanguage', code)
        switch(code) {
            case 'USD':
            case 'EURO':
                localStorage.setItem('i18n_locale','en')
                break;
            case 'VN':
                localStorage.setItem('i18n_locale','vi')
                break;
            default:
            // code block
        }  
    },
    async registerNew({commit, dispatch}, data) {
        try {
            const headers = {'Content-Type': 'application/json'}
            return await axiosInstance.post('/api/v1/auth/register', data, {
                headers,
            }).then(async res => {
                dispatch('showNotification', {
                    title: `Success !`,
                    type: 'success',
                    text: `Create account success,now confirm your phone number !`,
                })
                // await router.push({name: 'login', params: {email: data.email, password: data.password}})
                return res
            }).catch(error => {
                dispatch('showNotification', {
                    title: 'Error',
                    type: 'error',
                    text: error.response.data.message,
                })
                console.log(error)
                return false
            })
        } catch (error) {
            dispatch('showNotification', {
                title: 'Error',
                type: 'error',
                text: error.response.data.message,
            })
            console.log(error)
            return false
        }
    },
    async checkOTPPhone({commit, dispatch}, data) {
        commit('SHOW_LOADING', true)
        try {
            const headers = {'Content-Type': 'application/json'}
            return await axiosInstance.post('/api/v1/auth/active', data, {
                headers,
            }).then(async res => {
                console.log('res confirm', res)
                dispatch('showNotification', {
                    title: `Success !`,
                    type: 'success',
                    text: `Success, now login to system !`,
                })
                commit('SHOW_LOADING', false)
                // await router.push({name: 'login', params: {email: data.email, password: data.password}})
                window.location.href = '/login'
                return res
            }).catch(error => {
                commit('SHOW_LOADING', false)
                dispatch('showNotification', {
                    title: 'Error',
                    type: 'error',
                    text: error.response.data.message,
                })
                console.log(error)
                return false
            })
        } catch (error) {
            commit('SHOW_LOADING', false)
            dispatch('showNotification', {
                title: 'Error',
                type: 'error',
                text: error.response.data.message,
            })
            console.log(error)
            return false
        }
    },
    async resendOTPAPI({commit, dispatch}, data) {
        try {
            const headers = {'Content-Type': 'application/json'}
            return await axiosInstance.post('/api/v1/auth/send', data, {
                headers,
            }).then(async res => {
                console.log('res resendOTPAPI', res)
                dispatch('showNotification', {
                    title: `Success !`,
                    type: 'success',
                    text: `OTP sent your phone number !`,
                })
                return res
            }).catch(error => {
                dispatch('showNotification', {
                    title: 'Error',
                    type: 'error',
                    text: error.response.data.message,
                })
                console.log(error)
                return false
            })
        } catch (error) {
            return false
        }
    },
    async getWpRegion({commit, state}, {}) {
        try {
            return await axiosInstance.get('/api/wp/regions', {headers})
                .then(response => {
                    return response
                })
                .catch(error => {
                    console.log(error)
                    return false
                })
        } catch (error) {
            console.log(error)
            return false
        }
    },
    async getCpanelRegion({commit, state}, {}) {
        try {
            return await axiosInstance.get('/api/regions/cpanel', {headers})
                .then(response => {
                    return response
                })
                .catch(error => {
                    console.log(error)
                    return false
                })
        } catch (error) {
            console.log(error)
            return false
        }
    },
    async getWpSize({commit, state}, {}) {
        try {
            return await axiosInstance.get('/api/wp/sizes', {headers})
                .then(response => {
                    return response
                })
                .catch(error => {
                    console.log(error)
                    return false
                })
        } catch (error) {
            console.log(error)
            return false
        }
    },
    async getWpLogSocket({commit, state}, id) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
            return await axiosInstance.get(`/api/wp/${id}/log`, {headers})
                .then(response => {
                    return response
                })
                .catch(error => {
                    console.log(error)
                    return false
                })
        } catch (error) {
            console.log(error)
            return false
        }
    },
    async getTransactions({commit, state}, id) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
            return await axiosInstance.get(`/api/payments/stripe`, {headers})
                .then(response => {
                    return response
                })
                .catch(error => {
                    console.log(error)
                    return false
                })
        } catch (error) {
            console.log(error)
            return false
        }
    },
    async getListMethod({commit, state}, id) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
            return await axiosInstance.get(`/api/payments/stripe/methods`, {headers})
                .then(response => {
                    return response
                })
                .catch(error => {
                    console.log(error)
                    return false
                })
        } catch (error) {
            console.log(error)
            return false
        }
    },
    async getInvoices({commit, state}, id) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
            return await axiosInstance.get(`/api/payments/stripe/invoices`, {headers})
                .then(response => {
                    return response
                })
                .catch(error => {
                    console.log(error)
                    return false
                })
        } catch (error) {
            console.log(error)
            return false
        }
    },
    async getWordPress({commit, state}, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
            return await axiosInstance.get(`/api/wp`, {headers})
                .then(response => {
                    return response
                })
                .catch(error => {
                    console.log(error)
                    return false
                })
        } catch (error) {
            console.log(error)
            return false
        }
    },
    async getWordPressID({commit, state}, id) {
        try {
            const headers = {
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
            return await axiosInstance
                .get(`/api/wp/${id}`, { headers: headers })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.log(error)
                    return false
                })
        } catch (error) {
            console.log(error)
            return false
        }
    },
    async getLocationSearch({commit, state}, str) {
        try {
            console.log('str', str)
            const headers = {
                'Content-Type': 'application/json',
                Authorization: sessionStorage.getItem('accessToken')
            }
            return await axiosInstance.get(`/api/locations/search?search=${str}`, {headers})
        } catch (error) {
            return false
        }
    },
    async payment({commit, dispatch}, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
            commit('SHOW_LOADING', true)
            return await axiosInstance.post('/api/payments/checkout', data, {
                headers,
            }).then(async res => {
                commit('SHOW_LOADING', false)
                return res
            }).catch(error => {
                commit('SHOW_LOADING', false)
                console.log(error)
                return false
            })
        } catch (error) {
            console.log('error catch')
            commit('SHOW_LOADING', false)
            return false
        }
    },
    async createWP({commit, dispatch}, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
            commit('SHOW_LOADING', true)
            return await axiosInstance.post('/api/wp', data, {
                headers,
            }).then(async res => {
                commit('SHOW_LOADING', false)
                return res
            }).catch(error => {
                commit('SHOW_LOADING', false)
                console.log(error)
                return false
            })
        } catch (error) {
            console.log('error catch',error)
            commit('SHOW_LOADING', false)
            return false
        }
    },
    async updateDiskWP({commit, dispatch}, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
            commit('SHOW_LOADING', true)
            return await axiosInstance.put(`/api/wp/${data.id}/disk`, data, {
                headers,
            }).then(async res => {
                commit('SHOW_LOADING', false)
                return res
            }).catch(error => {
                commit('SHOW_LOADING', false)
                console.log(error)
                return false
            })
        } catch (error) {
            console.log('error catch',error)
            commit('SHOW_LOADING', false)
            return false
        }
    },
    async updateDomainWP({commit, dispatch}, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
            commit('SHOW_LOADING', true)
            return await axiosInstance.put(`/api/wp/${data.id}/domain`, data, {
                headers,
            }).then(async res => {
                commit('SHOW_LOADING', false)
                return res
            }).catch(error => {
                commit('SHOW_LOADING', false)
                console.log(error)
                return false
            })
        } catch (error) {
            console.log('error catch',error)
            commit('SHOW_LOADING', false)
            return false
        }
    },
    async updateSizeWPAPI({commit, dispatch}, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
            commit('SHOW_LOADING', true)
            return await axiosInstance.put(`/api/wp/${data.id}`, data, {
                headers,
            }).then(async res => {
                commit('SHOW_LOADING', false)
                return res
            }).catch(error => {
                commit('SHOW_LOADING', false)
                console.log(error)
                return false
            })
        } catch (error) {
            console.log('error catch',error)
            commit('SHOW_LOADING', false)
            return false
        }
    },
    async getTransID({commit},transId) {
        try {
            let token = sessionStorage.getItem('accessToken') != null ? sessionStorage.getItem('accessToken') : localStorage.getItem('accessToken')
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
            return await axiosInstance.get(`/api/payments/get-transaction?transactionCode=${transId}`, {headers})
                .then(response => {
                    return response
                })
                .catch(error => {
                    console.log(error)
                    return false
                })
        } catch (error) {
            console.log(error)
            return false
        }
    },
    async getSecretStripe({commit},token) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
            return await axiosInstance.get('/api/v1/auth/card', {headers})
                .then(response => {
                    let result = response.data.result
                    // console.log('rs getSecretStripe', result)
                    sessionStorage.setItem('clientStripe', result.client_secret)
                    // commit('SET_ME',JSON.stringify(result))
                    return true
                })
                .catch(error => {
                    console.log(error)
                    return false
                })
        } catch (error) {
            console.log(error)
            return false
        }
    },
    async getDNSList({commit, state}, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
            return await axiosInstance.get(`/api/dns`, {headers})
                .then(response => {
                    return response
                })
                .catch(error => {
                    console.log(error)
                    return false
                })
        } catch (error) {
            console.log(error)
            return false
        }
    },
    async createDNS({commit, dispatch}, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
            commit('SHOW_LOADING', true)
            return await axiosInstance.post('/api/dns', data, {
                headers,
            }).then(async res => {
                commit('SHOW_LOADING', false)
                return res
            }).catch(error => {
                commit('SHOW_LOADING', false)
                console.log(error)
                return false
            })
        } catch (error) {
            console.log('error catch',error)
            commit('SHOW_LOADING', false)
            return false
        }
    },
    async getDetailDNS({commit, state}, id) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
            return await axiosInstance.get(`/api/dns/${id}`, {headers})
                .then(response => {
                    return response
                })
                .catch(error => {
                    console.log(error)
                    return false
                })
        } catch (error) {
            console.log(error)
            return false
        }
    },
    async getDomainRecords({commit, state}, id) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
            return await axiosInstance.get(`/api/dns/${id}/records`, {headers})
                .then(response => {
                    return response
                })
                .catch(error => {
                    console.log(error)
                    return false
                })
        } catch (error) {
            console.log(error)
            return false
        }
    },
    async checkoutCreditCardAPI({commit, state}, amount) {
        commit('SHOW_LOADING', true)
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
            return await axiosInstance.post(`/api/payments/stripe/checkout`, amount,{headers})
                .then(response => {
                    return response
                })
                .catch(error => {
                    console.log(error)
                    return false
                })
        } catch (error) {
            console.log(error)
            return false
        }
    },
    async attachMethodAPI({commit, dispatch}, methodId) {
        commit('SHOW_LOADING', true)
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
            return await axiosInstance.put(`/api/payments/stripe/attach`, {methodId:methodId},{headers})
                .then(response => {
                    commit('SHOW_LOADING', false)
                    dispatch('showNotification', {
                        title: `Success !`,
                        type: 'success',
                        text: 'Attached method payment !',
                    })
                    return response
                })
                .catch(error => {
                    console.log(error)
                    commit('SHOW_LOADING', false)
                    dispatch('showNotification', {
                        title: `Error !`,
                        type: 'error',
                        text: `Can not attach method payment !`,
                    })
                    return false
                })
        } catch (error) {
            commit('SHOW_LOADING', false)
            console.log(error)
            dispatch('showNotification', {
                title: `Error !`,
                type: 'error',
                text: `Can not attach method payment !`,
            })
            return false
        }
    },
    async confirmCreditCardAPI({commit, state}, data) {
        commit('SHOW_LOADING', true)
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
            return await axiosInstance.post(`/api/payments/stripe/${data.transId}/confirm`, {method_id: data.methodId},{headers})
                .then(response => {
                    commit('SHOW_LOADING', false)
                    return response
                })
                .catch(error => {
                    console.log(error)
                    commit('SHOW_LOADING', false)
                    return false
                })
        } catch (error) {
            commit('SHOW_LOADING', false)
            console.log(error)
            return false
        }
    },
    async unLockAPI({commit, dispatch}, data) {
        try {
            return await axiosInstance.post('/api/v1/auth/login', data, {
                headers,
            }).then(async res => {
                return res
            }).catch(error => {
                dispatch('showNotification', {
                    title: 'Error',
                    type: 'error',
                    text: 'Password invalid, please try again !',
                })
                return false
            })
        } catch (error) {
            console.log(error)
            dispatch('showNotification', {
                title: 'Error',
                type: 'error',
                text: 'Password invalid, please try again !',
            })
            return false
        }
    },
    async DNScheck({commit, state}, domain) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
            return await axiosInstance.get(`/api/dns/check?domain=${domain}`, {headers})
                .then(response => {
                    return response
                })
                .catch(error => {
                    console.log(error)
                   
                    return false
                })
        } catch (error) {
            console.log(error)
            return false
        }
    },
    async addRecordToDomain({commit, dispatch}, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
            commit('SHOW_LOADING', true)
            return await axiosInstance.post(`/api/dns/${data.id}/records`, data, {
                headers,
            }).then(async res => {
                commit('SHOW_LOADING', false)
                return res
            }).catch(error => {
                commit('SHOW_LOADING', false)
                console.log(error)
                dispatch('showNotification', {
                    title: 'Error',
                    type: 'error',
                    text: 'Fail to add record to domain !',
                })
                return false
            })
        } catch (error) {
            console.log('error catch',error)
            commit('SHOW_LOADING', false)
            dispatch('showNotification', {
                title: 'Error',
                type: 'error',
                text: 'Fail to add record to domain !',
            })
            return false
        }
    },


    async deleteDNSAPI({commit, dispatch}, id) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
            commit('SHOW_LOADING', true)
            return await axiosInstance.delete(`/api/dns/${id}`, {
                headers,
            }).then(async res => {
                commit('SHOW_LOADING', false)
                return res
            }).catch(error => {
                commit('SHOW_LOADING', false)
                console.log(error)
                return false
            })
        } catch (error) {
            console.log('error catch',error)
            commit('SHOW_LOADING', false)
            return false
        }
    },
}
