<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/">Home</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page"><span>Domain Record</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
        <div class="row layout-top-spacing">
            <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
                <div class="panel p-0">
                    <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">
                        <div class="statbox panel box box-shadow">
                            <div class="panel-heading">
                                <div v-if="domainDetail != null" class="d-flex justify-content-between">
                                    <h4>Danh sách bản ghi tên miền : <span style="color: #4361ee;font-weight: bold">{{ domainDetail.name || ''  }}</span></h4>
                                    <router-link :to="'/dns/domain/' + domainDetail.id + '/add-record'" tag="button" class="btn btn-primary" >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-plus"
                                        >
                                            <line x1="12" y1="5" x2="12" y2="19"></line>
                                            <line x1="5" y1="12" x2="19" y2="12"></line>
                                        </svg>
                                        Thêm bản ghi
                                    </router-link>
                                </div>
                            </div>
                            <div class="panel-body">
                                <div class="table-responsive">
                                    <table role="table" aria-busy="false" aria-colcount="5" class="table b-table table-bordered" id="__BVID__328">
                                        <thead role="rowgroup" class="">
                                        <tr role="row" class="">
                                            <th role="columnheader" scope="col" aria-colindex="1" class=""><div>Tên bản ghi</div></th>
                                            <th role="columnheader" scope="col" aria-colindex="1" class=""><div>Kiểu</div></th>
                                            <th role="columnheader" scope="col" aria-colindex="1" class=""><div>Giá trị</div></th>
                                            <th role="columnheader" scope="col" aria-colindex="1" class=""><div>Action</div></th>
                                        </tr>
                                        </thead>
                                        <tbody role="rowgroup">
                                            <tr v-if="listRecord.length" v-for="(item, i) in listRecord" :key="id" role="row" class="">
                                                <td aria-colindex="2" role="cell" class="">
                                                    <a :href="item.name" target="_blank">
                                                        <i class="fas fa-link"></i> {{ item.name }}
                                                    </a>
                                                </td>
                                                <td aria-colindex="2" role="cell" class="text-success">
                                                    {{item.type}}
                                                </td>
                                                <td aria-colindex="2" role="cell" class="">
                                                    <span v-for="ip in item.data">
                                                        {{ip}} <br>
                                                    </span> 
                                                </td>
                                                <td aria-colindex="2" role="cell" class="text-center">
                                                    <a class="btn dropdown-toggle btn-icon-only" href="#" role="button" id="pendingTask" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            style="width: 24px; height: 24px"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            class="feather feather-more-horizontal"
                                                        >
                                                            <circle cx="12" cy="12" r="1"></circle>
                                                            <circle cx="19" cy="12" r="1"></circle>
                                                            <circle cx="5" cy="12" r="1"></circle>
                                                        </svg>
                                                    </a>

                                                    <ul class="dropdown-menu dropdown-menu-end">
                                                        <li>
                                                            <router-link tag="a" class="dropdown-item" :to="'/dns/domain/' + $route.params.id + '/record/' + item.id">
                                                                Edit
                                                            </router-link>
                                                        </li>
                                                        <li>
                                                            <a href="javascript:void(0);" class="dropdown-item"> Delete </a>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr v-if="!listRecord.length" role="row" class="">
                                                <p>
                                                    Record Empty !
                                                </p>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="modal animated fadeInDown" id="modalAddDomain" tabindex="-1" role="dialog" aria-labelledby="modalAddDomain" aria-hidden="true">
            <div style="max-width: 800px" class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalUpdateDiskLabel">Tạo tên miền</h5>
                        <button type="button" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close" class="btn-close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row justify-content-between m-0">
                            <div class="form-group">
                                <p>Nhập tên miền</p>
                                <input type="text" class="form-control" placeholder="https://" required />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn" data-dismiss="modal" data-bs-dismiss="modal"><i class="flaticon-cancel-12"></i> Huỷ</button>
                        <button type="button" class="btn btn-primary" @click="">Xác Nhận</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal animated fadeInDown" id="modalAlertNotActive" tabindex="-1" role="dialog" aria-labelledby="modalAlertNotActive" aria-hidden="true">
            <div style="max-width: 800px" class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalAlertNotActiveLabel">Kích hoạt domain</h5>
                        <button type="button" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close" class="btn-close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-light-danger alert-dismissible border-0 mb-4" role="alert">
                            <strong>Thông báo!</strong> Domain của bạn chưa được kích hoạt trên DNS
                        </div>
                        <h4>Bản ghi NS hiện tại của domain https://www.godaddy.com/vi-vn</h4>
                        <p>(Quá trình cập nhật có thể mất tới 24h do cache trên hệ thống DNS)</p>
                        <h4>Để kích hoạt, vui lòng trỏ bản ghi NS về :</h4>
                        <p>1. ns1.test.vn</p>
                        <p>2. ns1.test.vn</p>
                        <p>3. ns1.test.vn</p>
                        <p>
                            Bạn sẽ nhận được thông báo ngay khi domain của bạn thay đổi trạng thái .Bạn cũng có thể cập nhật trạng thái
                            kích hoạt mới nhất của domain bằng cách bấm nút bên dưới
                        </p>
                        <p class="mt-3">Cập nhật lần cuối : <b style="color: #2196f3">2022-11-10 15:30:20</b></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn" data-dismiss="modal" data-bs-dismiss="modal"><i class="flaticon-cancel-12"></i> Đóng</button>
                        <button type="button" class="btn btn-primary" @click="">Kiểm Tra</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';

import { useMeta } from '@/composables/use-meta';
useMeta({ title: 'Domain Record' });
</script>
<script>
import {mapActions, mapGetters} from "vuex";
import $ from 'jquery'
export default {
    name: "DomainRecordList",
    data() {
        return {
            domainDetail: null,
            listRecord: [],
        }
    },
    mounted() {
        $(".modal-backdrop").remove()
    },
    created() {
        this.getDetailDNS(this.$route.params.id).then(res => {
            // console.log('res getDetailDNS', res)
            this.domainDetail = res.data.result
            this.getDomainRecords(this.$route.params.id).then(res => {
                // console.log('res getDomainRecords', res)
                this.listRecord = res.data.result.data
            }).catch(e => {
                console.log(e)
            })
        }).catch(e => {
            console.log(e)
        })
    },
    computed:{
        ...mapGetters(['getterMe','getterCurrency'])
    },
    methods: {
        ...mapActions(['getDetailDNS','getDomainRecords']),
        convertAmountTransaction(x) {
            return parseInt(x).toLocaleString('vi-VN')
        },
        convertCurrency(x) {
            return x.toLocaleString('vi-VN')
        },
        formatCreatedAt(date){
            date = new Date(date);
            let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s;
        }
    },
}
</script>

<style scoped>
.table > tbody > tr > td {
    padding: 13px;
    text-align: center;
}
.table > th {
    text-align: center;
}
</style>
