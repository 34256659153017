<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/">Home</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page"><span>Link Card</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
        <div class="row layout-spacing mt-5">
            <div class="col-lg-8 col-12 layout-top-spacing m-auto">
                <div class="bio layout-spacing">
                    <div class="panel">

                        <div class="panel-body">
                            <div>
                                <p style="font-size: 17px">Liên kết thẻ</p>
                            </div>
                            <div class="box-payment-card">
                                <!--            FORM PAYMENT STRIPE                    -->
                                <div class="row div-input-checkout d-block">
                                    <form id="payment-form-stripe">
                                        <div id="payment-element">
                                            <!-- Elements will create form elements here -->
                                        </div>
                                        <button class="btn btn-primary my-5 w-100" id="submit">Xác nhận liên kết thẻ</button>
                                        <div id="error-message">
                                            <!-- Display error message to your customers here -->
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import '@/assets/sass/scrollspyNav.scss';
import '@/assets/sass/users/user-profile.scss';
</script>
<script>
import {mapActions, mapGetters} from "vuex";
import $ from 'jquery'
export default {
    name: "LinkCard",
    data() {
        return {
            loading: false,
            amount: '________',
            priceActive: -1,
            paymentTypeActive: -1,
            showFormInputPayment: false,
            paymentMethod: 1,
            listTransaction: [],
            paymentType: [
                {
                    text: 'Alepay',
                    value: 2,
                    img: 'alego2.png',
                    width: '100px'
                },
                {
                    text: 'VietQR',
                    value: 3,
                    img: 'vietqr2.png',
                    width: '102px'
                },
                {
                    text: 'ATM Nội địa',
                    value: 4,
                    img: 'debit-card-icon-10.jpg',
                    width: '56px'
                },
                {
                    text: 'Thẻ quốc tế',
                    value: 5,
                    img: 'credit-card.png',
                    width: '58px'
                },
                {
                    text: 'VN Pay',
                    value: 6,
                    img: 'vnpay.png',
                    width: '52px'
                },
                {
                    text: 'Credit Card',
                    value: 7,
                    img: 'epispayment.png',
                    width: '94px'
                },
            ],
            priceVND: [
                {
                    text: '100.000đ',
                    value: 100000
                },
                {
                    text: '500.000đ',
                    value: 500000
                },
                {
                    text: '1.000.000đ',
                    value: 1000000
                },
                {
                    text: '2.000.000đ',
                    value: 2000000
                },
                {
                    text: '3.000.000đ',
                    value: 3000000
                },
                {
                    text: '5.000.000đ',
                    value: 5000000
                },
                {
                    text: '10.000.000đ',
                    value: 10000000
                }
            ],
            priceAUD: [
                {
                    text: '5 A$',
                    value: 5
                },
                {
                    text: '10 A$',
                    value: 10
                },
                {
                    text: '20 A$',
                    value: 20
                },
                {
                    text: '30 A$',
                    value: 30
                },
                {
                    text: '40 A$',
                    value: 40
                },
                {
                    text: '50 A$',
                    value: 50
                },
                {
                    text: '60 A$',
                    value: 60
                }
            ],
            modalPayMe: null,
            checkoutURLPayMe: '',
            showFormStripe: true,
            showFormPayme: false,
        }
    },
    created() {
    },
    async mounted() {
        localStorage.setItem('accessToken',sessionStorage.getItem('accessToken'))

        // ============= PAYMENT  STRIPE ==================
        const stripe = Stripe(process.env.VUE_APP_PAYMENT_STRIPE_KEY);
        const options = {
            clientSecret: sessionStorage.getItem('clientStripe'),
            // Fully customizable with appearance API.
            appearance: {
                theme: 'flat',
            },
        };

        // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
        const elements = stripe.elements(options);

        // Create and mount the Payment Element
        const paymentElement = elements.create('payment');
        paymentElement.mount('#payment-element');


        const form = document.getElementById('payment-form-stripe');

        form.addEventListener('submit', async (event) => {
            event.preventDefault();

            const {error} = await stripe.confirmSetup({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                    return_url: process.env.VUE_APP_URL + '/payment',
                }
            });

            if (error) {
                // This point will only be reached if there is an immediate error when
                // confirming the payment. Show error to your customer (for example, payment
                // details incomplete)
                const messageContainer = document.querySelector('#error-message');
                messageContainer.textContent = error.message;
            } else {
                // Your customer will be redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer will be redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
            }
        });

        // Retrieve the "setup_intent_client_secret" query parameter appended to
        // your return_url by Stripe.js
        const clientSecret = new URLSearchParams(window.location.search).get(
            'setup_intent_client_secret'
        );
        //
        // // Retrieve the SetupIntent
        stripe.retrieveSetupIntent(clientSecret).then(({setupIntent}) => {
            switch (setupIntent.status) {
                case 'succeeded': {
                    this.$store.dispatch('showNotification',{
                        title: `Success  !`,
                        type: 'success',
                        text: `Success! Your payment method has been saved.'`,
                    })
                    break;
                }

                case 'processing': {
                    this.$store.dispatch('showNotification',{
                        title: `Processing  !`,
                        type: 'success',
                        text: "Processing payment details. We'll update you when processing is complete.",
                    })
                    break;
                }

                case 'requires_payment_method': {
                    this.$store.dispatch('showNotification',{
                        title: `Failed  !`,
                        type: 'error',
                        text:  'Failed to process payment details. Please try another payment method.',
                    })
                    break;
                }
            }
        });
        // ============= END PAYMENT  STRIPE ==================
    },
    computed:{
        ...mapGetters(['getterMe','getterCurrency','getterRegionCode']),
        listPrice(){
            switch(this.getterRegionCode) {
                case "VN":
                    return this.priceVND
                case "AUD":
                   return this.priceAUD
                default:
                // code block
            }
        },
        getListPaymentType(){
            if (this.getterRegionCode === 'VN'){
                // vietnam không dùng square
                return this.paymentType.filter(function (element) {
                    return element.value < 7
                });
            }else{
                // aud, usd, euro chỉ dùng square
                return this.paymentType.filter(function (element) {
                    return element.value === 7
                });
            }
        }
    },
    methods: {
        ...mapActions(['getTransactions','payment']),
        convertAmountTransaction(x) {
            return parseInt(x).toLocaleString('vi-VN')
        },
        convertCurrency(x) {
            return x.toLocaleString('vi-VN')
        },
        setPriceActive(index,value){
            this.priceActive = index
            this.amount = value
        },
        setPaymentTypeActive(index,value){
            this.showFormStripe = false
            this.paymentTypeActive = index
            this.showFormInputPayment = false
            this.paymentMethod = value
            if (index === 5) {
                this.showFormInputPayment = true
            } else {
            }
        },
        formatCreatedAt(date){
            date = new Date(date);
            let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s;
        },
        payCreditCard(){
            this.showFormStripe = true;
            this.showFormPayme = false
        },
    },
}
</script>

<style scoped>
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .box-payment-card{
        margin: auto;
    }
    .btn-checkout{
        height: 55px;
        font-size: 18px;
        margin-top: 30px;
        width: 45%;
    }
    .bio .b-skills {
        padding: 20px;
        cursor: pointer;
        justify-content: center;
    }
    .bio .b-skills h4{
        font-weight: bold;
        margin-bottom: 0;
    }
    .deno-item.active .b-skills{
        background: #4361ee;
        transition: transform 2s;
    }
    .deno-item.active h4{
        color: white;
    }
    .free_price{
        border: none;
        width: 174px;
        box-shadow: none !important;
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
        color: #3b3f5c;
    }
    .deno-item.active .free_price{
        background-color: #4361ee;
        color: white;
    }
    #currency{
        position: absolute;
        top: 10px;
        font-size: 1.5rem;
        font-weight: bold;
        right: 0;
        color: #3b3f5c;
    }
    .deno-item.active #currency{
        color: white;
    }
    .deno-item.active ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
    }
    ::selection {
        background: none !important;
        color: unset !important;
    }
    .pay-item{
        width: 100%;
        display: flex;
        padding: 20px 60px;
        justify-content: space-between;
        align-items: center;
        max-height: 75px;
    }
    .pay-item img{
        width: 100px;
    }

    .div-input-checkout {
        margin: auto;
        text-align: center;
        width: 100%;
    }
    @media (max-width: 769px) {
        .price-box .bio .b-skills {
            padding: 10px;
            cursor: pointer;
            justify-content: center;
        }
        .payment-type-box .pay-item {
            padding: 0 20px;
        }
        .pay-item{
            max-height: 42px;
        }
        .btn-checkout{
            height: 45px;
            font-size: 16px;
            margin-top: 10px;
            width: 100%;
        }
        .div-input-checkout {
            width: 100%;
        }
        .box-payment-card{
            width: 100%;
        }
    }
    @media (max-width: 450px) {
        .pay-item{
            max-height: 20px;
        }
        .payment-type-box .pay-item{
            max-height: 30px !important;
        }
        
        .price-box .b-skills {
            padding: 10px;
        }
        .free_price{
            padding: 3px 0;
        }
        #currency{
            display: none;
        }
        .price-box h4{
            font-size: 16px;
        }
        .price-box .deno-item .free_price {
           font-size: 16px !important;
            width: 150px;
        }
        .payment-type-box h4{
            font-size: 16px;
        }
    }
</style>
