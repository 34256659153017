<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/">Home</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page"><span>PAAS Service</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
        <div class="row layout-top-spacing">
            <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
                <div class="panel p-0">
                    <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">
                        <div class="statbox panel box box-shadow">
                            <div class="panel-heading">
                                <div class="d-flex justify-content-between">
                                    <h4>PAAS</h4>
                                    <router-link to="/web-service/create" class="btn btn-primary">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-plus"
                                        >
                                            <line x1="12" y1="5" x2="12" y2="19"></line>
                                            <line x1="5" y1="12" x2="19" y2="12"></line>
                                        </svg>
                                        Create Project
                                    </router-link>
                                    
                                </div>
                            </div>
                            <div class="panel-body">
                                <div class="table-responsive">
                                    <table role="table" aria-busy="false" aria-colcount="5" class="table b-table table-bordered" id="__BVID__328">
                                        <thead role="rowgroup" class="">
                                        <tr role="row" class="">
                                            <th role="columnheader" scope="col" aria-colindex="1" class=""><div>Name</div></th>
                                            <th role="columnheader" scope="col" aria-colindex="2" class=""><div>Status</div></th>
                                            <th role="columnheader" scope="col" aria-colindex="2" class=""><div>Type</div></th>
                                            <th role="columnheader" scope="col" aria-colindex="2" class=""><div>Environment</div></th>
                                            <th role="columnheader" scope="col" aria-colindex="2" class=""><div>Region</div></th>
                                            <th role="columnheader" scope="col" aria-colindex="4" class=""><div>Last Deployed</div></th>
                                        </tr>
                                        </thead>
                                        <tbody role="rowgroup">
                                        <tr v-for="(item, i) in listWordPress" :key="id" role="row" class="">
                                            <td aria-colindex="2" role="cell" class="">
                                                <a href="#">
                                                    <i class="fas fa-link"></i> test-docker
                                                </a>
                                            </td>
                                            <td aria-colindex="2" role="cell">
                                                <span class="badge badge-success me-1">Deployed Success</span>
                                            </td>
                                            <td aria-colindex="2" role="cell">
                                                Web Service
                                            </td>
                                            <td aria-colindex="2" role="cell">
                                                <span class="badge badge-info me-1">DOCKER</span>
                                            </td>
                                            <td aria-colindex="2" role="cell">
                                                Singapore
                                            </td>
                                            <td aria-colindex="2" role="cell">
                                                1 hour ago
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal animated fadeInDown" id="modalAddProject" tabindex="-1" role="dialog" aria-labelledby="modalAddProject" aria-hidden="true">
            <div style="max-width: 800px" class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalUpdateDiskLabel">Create Project</h5>
                        <button type="button" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close" class="btn-close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row justify-content-between m-0">
                            <div class="form-group">
                                <p>Name (lowercase letters,numbers,and "-" only)</p>
                                <input type="text" class="form-control" placeholder="Ex: docker-sv1" required />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn" data-dismiss="modal" data-bs-dismiss="modal"><i class="flaticon-cancel-12"></i> Huỷ</button>
                        <button type="button" class="btn btn-primary" @click="">Xác nhận</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useMeta } from '@/composables/use-meta';
useMeta({ title: 'PAAS' });
</script>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "PAASList",
    data() {
        return {
            listWordPress: [],
        }
    },
    created() {
        this.getWordPress().then(res => {

            console.log('res getWordPress', res)
            this.listWordPress = res.data.result
        }).catch(e => {
            console.log(e)
        })
    },
    computed:{
        ...mapGetters(['getterMe','getterCurrency'])
    },
    methods: {
        ...mapActions(['getWordPress']),
        convertAmountTransaction(x) {
            return parseInt(x).toLocaleString('vi-VN')
        },
        convertCurrency(x) {
            return x.toLocaleString('vi-VN')
        },
        formatCreatedAt(date){
            date = new Date(date);
            let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s;
        }
    },
}
</script>

<style scoped>
.table > tbody > tr > td {
    padding: 13px;
}
</style>
