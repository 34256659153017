<template>
    <div class="m-auto position-relative">
        <div class="card m-auto ct-sc">
            <div class="card-body text-center m-auto" style="width: 95%;">
                <img style="margin: auto; width: 100px" :src="require('@/assets/images/success.png')" alt=""/>
                <h4 class="title-sc font-weight-bold">Giao dịch thanh toán thành công</h4>
                <p v-if="transactionCode !== ''" class="font-weight-bold mb-3"
                   style="font-weight: bold;padding: 5px">THÔNG TIN GIAO DỊCH</p>
                <div v-if="transactionCode !== ''" class="payment-info">
                    <div class="d-flex justify-content-between mt-3">
                        <h5 class="font-weight-bold">Mã giao dịch</h5>
                        <h6>{{ transactionCode }}</h6>
                    </div>
                    <div class="d-flex justify-content-between mt-3">
                        <h5 class="font-weight-bold">Số tiền thanh toán</h5>
                        <h6>{{ convertCurrency(amount) }} {{ getterCurrency.lower }}</h6>
                    </div>
                    <div class="d-flex justify-content-between">
                        <h5 class="font-weight-bold">Nội dung thanh toán</h5>
                        <h6>{{ note }}</h6>
                    </div>
                    <div class="d-flex justify-content-between mt-3">
                        <h5 class="font-weight-bold">Thời gian</h5>
                        <h6>{{ formatCreatedAt(createdAt) }}</h6>
                    </div>
                </div>
            </div>
            <router-link class="m-auto py-4 d-block" style="text-align: center;color: #4361ee" to="/">
                Về trang chủ
            </router-link>

        </div>

    </div>
</template>

<script setup>
import '@/assets/sass/authentication/auth-boxed.scss';
import {useMeta} from '@/composables/use-meta';

useMeta({title: 'Order Success'});
</script>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'OrderSuccess',
    data() {
        return {
            transactionCode: '',
            note: '',
            createdAt: '',
            amount: 0
        }
    },
    created() {
        let transId = Object.entries(this.$route.query).filter(el => {
            if (el[0].includes('transId')) {
                return el
            }
        })
        transId = transId[0][1]
        this.getTransID(transId).then(res => {
            if (res && res.data.message === "success") {
                this.transactionCode = res.data.result.transactionCode
                this.note = res.data.result.description
                this.amount = res.data.result.amount
                this.createdAt = res.data.result.createdAt
            }
        }).catch(e => {
            console.log(e)
        })
    },
    methods: {
        ...mapActions(['getTransID']),
        convertCurrency(x) {
            return x.toLocaleString('vi-VN')
        },
        formatCreatedAt(date) {
            date = new Date(date);
            let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s;
        }
    },
    computed: {
        ...mapGetters(['getterCurrency'])
    }
}
</script>
<style scoped>
.ct-sc {
    width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 25%);
}

.title-sc {
    font-weight: bold;
    padding: 10px;
}

.font-weight-bold {
    font-weight: bold;
}

@media (max-width: 768px) {
    .ct-sc {
        width: 85%;
    }
}
</style>
