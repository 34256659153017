<template>
    <div :class="[$store.state.layout_style, $store.state.menu_style]">
        <component v-if="!isLockScreen" v-bind:is="layout"></component>
        <Loading :class="{ show: isLoading }" />
        <notifications />
        <lockscreen v-if="isLockScreen"></lockscreen>
    </div>
</template>
<script setup>
    import { computed } from 'vue';

    import '@/assets/sass/app.scss';

    import { useMeta } from '@/composables/use-meta';
    import { useStore } from 'vuex';
    import Lockscreen from "@/views/auth/lockscreen";

    useMeta({ title: 'EPIS CLoud' });

    const store = useStore();

    const layout = computed(() => {
        return store.getters.layout;
    });
</script>
<script>
    // layouts
    import appLayout from './layouts/app-layout.vue';
    import authLayout from './layouts/auth-layout.vue';
    import Loading from "@/components/Loading";
    import {mapActions, mapState} from "vuex";
    export default {
        components: {
            app: appLayout,
            auth: authLayout,
        },
        computed: {
            ...mapState(['isLoading','isLockScreen']),
        },
        methods: {
            ...mapActions(['getMe'])
        },
        created() {
            let token = sessionStorage.getItem('accessToken')
            this.getMe(token)
        }
    };
</script>
<style>
.invalid-cloud{
    font-size: 14px;
    color: red;
}
.font-weight-bold{
    font-weight: bold;
}
.text-black{
 color: #3b3f5c;   
}
.pl-3{
    padding-left: 16px;
}
.text-theme{
    color : #4361ee
}
@media (max-width: 768px) {
    .navbar .navbar-item .nav-item.user-profile-dropdown{
        margin: 0;
    }
}
</style>
