<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/">Home</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page"><span>Project</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
        <div>
            <div class="col-12 layout-spacing mt-4">
                <div class="panel p-0">
                    <div class="col-lg-12 col-12">
                        <div class="statbox panel box box-shadow">
                            <div class="panel-heading">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <h4>Create new Web Service</h4>
                                        <p>Connect your git repository or use an existing public repository URL</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-spacing mb-5 d-flex col-12 row">
            <div class="col-lg-9 col-12">
                <div class="statbox panel box box-shadow">
                    <div class="panel-heading">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h4>Connect a repository</h4>
                            </div>
                        </div>
                    </div>
                    <div class="panel-body py-5 row">
                        <div class="repo-item row d-flex justify-content-between">
                            <div class="col-lg-8 col-12 mb-3">
                                <span>  <i style="font-size: 18px;color: #0a0d26" class="fa-brands fa-github px-3"></i></span>
                                <span style="font-size: 18px;color: #0a0d26">example/test-1</span>
                                <span class="px-4"> | </span>
                                <span>5m ago</span>
                            </div>

                            <button class="col-lg-4 col-12 btn-connect-git btn btn-primary">
                                <i class="fa-solid fa-plug px-2"></i>
                                Connect </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel col-lg-3 col-12">
                <div class="statbox panel box box-shadow p-4">
                    <h5><i style="font-size: 18px" class="fa-brands fa-github px-2"></i> Github</h5>
                    <p class="px-3 py-2">
                        <i class="fas fa-link"></i>
                        <router-link to="" style="font-size: 16px">
                            repo demo
                        </router-link>
                    </p>
                    <p class="px-3 py-2">
                        <router-link to="" style="color: #4361ee">Configure Account</router-link>
                    </p>
                </div>

                <div class="mt-3 p-4">
                    <h5><i style="font-size: 18px" class="fa-brands fa-gitlab px-2"></i> Gitlab</h5>
                    <p class="px-3 py-2" style="font-size: 16px;color: #4361ee">
                        <i class="fas fa-plus"></i>
                        <router-link to="" style="color: #4361ee">
                            Connect Account
                        </router-link>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';

import { useMeta } from '@/composables/use-meta';
useMeta({ title: 'Create Web Service' });
</script>
<script>
import {mapActions, mapGetters} from "vuex";
import $ from 'jquery'
export default {
    name: "WebServiceAdd",
    data() {
        return {
            domainDetail: null,
            name: "",
            type: "A",
            content: "",
            ttl: "",
            priority: "",
            proxied: true,
        }
    },
    mounted() {
        $(".modal-backdrop").remove()
    },
    created() {
        this.getDetailDNS(this.$route.params.id).then(res => {
            this.domainDetail = res.data.result
        }).catch(e => {
            console.log(e)
        })
    },
    computed:{
        ...mapGetters(['getterMe','getterCurrency'])
    },
    methods: {
        ...mapActions(['getDetailDNS','addRecordToDomain']),
        convertAmountTransaction(x) {
            return parseInt(x).toLocaleString('vi-VN')
        },
        convertCurrency(x) {
            return x.toLocaleString('vi-VN')
        },
        formatCreatedAt(date){
            date = new Date(date);
            let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s;
        },
        createProject(){
            if (this.name === '' || this.content === '' || this.priority === '' || this.ttl === ''){
                this.$store.dispatch('showNotification',{
                    title: 'Error !',
                    type: 'error',
                    text: 'Please input info record !',
                })
                return false;
            }
            let obj = {
                "id" : this.$route.params.id,
                "name": this.name,
                "type": this.type,
                "content": this.content,
                "ttl": this.ttl,
                "priority": parseInt(this.priority),
                "proxied": this.proxied
            }
            this.addRecordToDomain(obj).then(res => {
                console.log('addRecordToDomain',res)
            }).catch(e => {
                console.log(e)
                return false
            })
        }
    },
}
</script>

<style scoped>
.table > tbody > tr > td {
    padding: 13px;
}
.panel{
    border: none !important;
    background-color: unset !important;
}
.btn-connect-git{
    width: 300px;
}
</style>
