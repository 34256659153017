export default {
    getterMe: state => JSON.parse(state.me) != null ? JSON.parse(state.me) : JSON.parse(sessionStorage.getItem('me')),
    getterRegionCode: state => JSON.parse(sessionStorage.getItem('me')) != null ?  JSON.parse(sessionStorage.getItem('me')).region_code : 'VN',
    getterCurrency: state => {
        let currenCy = {
            lower: "đ",
            upper: "VNĐ",
        }
        let regionCode = JSON.parse(sessionStorage.getItem('me')) != null ?  JSON.parse(sessionStorage.getItem('me')).region_code : "VN";
        switch(regionCode) {
            case "VN":
                currenCy = {
                    lower: "đ",
                    upper: "VNĐ",
                }
                break;
            case "AUD":
                currenCy = {
                    lower: "aud",
                    upper: "AUD",
                }
                break;
            default:
            // code block
        }
        return currenCy;
    },
}
