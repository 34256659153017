
import Vuex from 'vuex'
import i18n from '../i18n';
import moduleCloud from './moduleCloud'
import { notify } from "@kyvg/vue3-notification";
export default new Vuex.Store({
    state: {
        layout: 'app',
        is_show_sidebar: true,
        is_show_search: false,
        is_dark_mode: false,
        dark_mode: 'light',
        locale: null,
        menu_style: 'vertical',
        layout_style: 'full',
        countryList: [
            { code: 'en', name: 'English' },
            { code: 'vi', name: 'Việt Nam' },
            { code: 'aud', name: 'Australia' },
        ],
        isLoading: false,
        isLockScreen: sessionStorage.getItem('lock_screen') === 'true',
    },
    mutations: {
        setLayout(state, payload) {
            state.layout = payload;
        },
        toggleSideBar(state, value) {
            state.is_show_sidebar = value;
        },
        toggleSearch(state, value) {
            state.is_show_search = value;
        },
        toggleLocale(state, value) {
            value = value || 'en';
            i18n.global.locale = value;
            localStorage.setItem('i18n_locale', value);
            state.locale = value;
        },

        toggleDarkMode(state, value) {
            //light|dark|system
            value = value || 'light';
            localStorage.setItem('dark_mode', value);
            state.dark_mode = value;
            if (value == 'light') {
                state.is_dark_mode = false;
            } else if (value == 'dark') {
                state.is_dark_mode = true;
            } else if (value == 'system') {
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    state.is_dark_mode = true;
                } else {
                    state.is_dark_mode = false;
                }
            }

            if (state.is_dark_mode) {
                document.querySelector('body').classList.add('dark');
            } else {
                document.querySelector('body').classList.remove('dark');
            }
        },

        toggleMenuStyle(state, value) {
            //horizontal|vertical|collapsible-vertical
            value = value || '';
            localStorage.setItem('menu_style', value);
            state.menu_style = value;
            if (!value || value === 'vertical') {
                state.is_show_sidebar = true;
            } else if (value === 'collapsible-vertical') {
                state.is_show_sidebar = false;
            }
        },

        toggleLayoutStyle(state, value) {
            //boxed-layout|large-boxed-layout|full
            value = value || '';
            localStorage.setItem('layout_style', value);
            state.layout_style = value;
        },
        SHOW_LOADING: (state, flag = false) => {
            state.isLoading = flag
        },
        SET_LOCK_SCREEN: (state, flag = false) => {
            state.isLockScreen = flag
        },
    },
    getters: {
        layout(state) {
            return state.layout;
        },
    },
    actions: {
        // eslint-disable-next-line no-unused-vars
        showNotification({ commit, dispatch }, data) {
            notify({
                title: data.title,
                text: data.text,
                type: data.type,
                duration: 8000,
            });
        },
    },
    modules: {
        moduleCloud
    },
    strict: process.env.DEV,
});
