<template>
    <div class="layout-px-spacing">
        <div class="row step-create">
            <div class="wizard">
                <div class="wizard-inner">
                    <div class="connecting-line"></div>
                    <ul class="nav nav-tabs" role="tablist">
                        <li role="presentation" :class="{ active: stepIndex >= 1 }">
                            <a href="#" data-toggle="tab" aria-controls="step1" role="tab" aria-expanded="true">
                                <span class="round-tab">1 </span> <i>Đăng Ký Gói</i>
                            </a>
                        </li>
                        <li role="presentation" :class="{ active: stepIndex >= 2 }">
                            <a href="#" data-toggle="tab" aria-controls="step2" role="tab" aria-expanded="false">
                                <span class="round-tab">2</span> <i>Thanh Toán</i>
                            </a>
                        </li>
                        <li role="presentation" :class="{ active: stepIndex === 3 }">
                            <a href="#" data-toggle="tab" aria-controls="step3" role="tab">
                                <span class="round-tab">3</span> <i>Khởi Tạo</i>
                            </a>
                        </li>
                    </ul>
                </div>
                <p class="text-center pt-3">Quá trình đang diễn ra, vui lòng đợi trong giây lát</p>
            </div>
            <div class="mt-5" v-if="showErrorPayment">
                <p>Id: {{errorPayment.id}}</p>
                <p>Amount paid: {{errorPayment.amount_paid}}</p>
                <p>Amount due: {{errorPayment.amount_due}}</p>
                <p>Hosted invoice url: {{errorPayment.hosted_invoice_url}}</p>
            </div>
           
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import '@/assets/sass/scrollspyNav.scss';

import { NumberSpinner } from 'vue3-number-spinner';

import { useMeta } from '@/composables/use-meta';
useMeta({ title: 'Touchspin' });


import '@/assets/sass/components/cards/card.scss';
import '@/assets/sass/scrollspyNav.scss';
import '@/assets/sass/pricing-table.scss';

import BreadCrumb from "@/views/components/BreadCrumb";
useMeta({ title: 'WordPress' });


</script>

<script>
import $ from "jquery";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "WordPressProgress",
    data() {
        return {
            stepIndex: 1,
            errorPayment: null,
            showErrorPayment: false
        }
    },
    mounted() {
        $(".modal-backdrop").remove()
    },
    created() {
        Pusher.logToConsole = true;
        var pusher = new Pusher(process.env.VUE_APP_YOUR_PUSHER_APP_KEY, {
            cluster: 'ap1',
            authEndpoint:  process.env.VUE_APP_API_SERVICE + '/api/v1/auth/pusher',
        });
        let userId = JSON.parse(sessionStorage.getItem('me')).id
        let key = `private-${userId}`;
        var channel = pusher.subscribe(key);

        // step 1
        console.log('===================================')
        channel.bind('subscription.created', function(data) {
            console.log('subscription.created', data)
        });
        channel.bind('subscription.updated', function(data) {
            console.log('subscription.updated', data)
        });
        // step 1
        channel.bind('invoice.payment_failed', function(data) {
            console.log('invoice.payment_failed', data)
            this.showErrorPayment = true
            this.stepIndex = 1
            this.errorPayment = {
                id: data.stripe.id,
                amount_paid: data.stripe.amount_paid,
                amount_due: data.stripe.amount_due,
                hosted_invoice_url: data.stripe.hosted_invoice_url,
            }
        });
        // step 2
        channel.bind('invoice.paid', function(data) {
            console.log('invoice.paid', data)
            this.$store.dispatch('showNotification',{
                title: `Success !`,
                type: 'success',
                text: `Let's Panel WordPress now !`,
            })
            var _this = this;
            _this.stepIndex = 2
            setTimeout(function (_this){
                _this.stepIndex = 2
               
                setTimeout(function (_this){
                    window.location.href = '/wordpress/' + this.$route.params.id
                },1000)
            },1000)
        });
    },
    computed:{
        ...mapGetters(['getterMe','getterCurrency'])
    },
    methods: {
        ...mapActions(['getWpRegion','getWpSize','createWP']),
    },
}
</script>
<style scoped>
.touchspin .form-control:disabled,
.touchspin .form-control[readonly] {
    background: #fff !important;
    text-align: center;
    color: #3b3f5c;
    cursor: text !important;
}
.dark .touchspin .form-control:disabled,
.dark .touchspin .form-control[readonly] {
    border: 1px solid #1b2e4b;
    color: #009688 !important;
    background: #1b2e4b !important;
}

.touchspin .btn {
    padding: 6px 12px;
}

.touchspin .vertical-spin {
    width: fit-content;
}

.touchspin .vertical-spin .input-group {
    flex-direction: column;
}

.touchspin .vertical-spin .btn {
    padding: 6px 10px;
    max-width: 45px;
}
.touchspin .vertical-spin .btn.top-minus-btn {
    border-top-right-radius: 4px !important;
    border-bottom-left-radius: 0px;
}
.touchspin .vertical-spin .btn.bottom-plus-btn {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 4px !important;
}

.touchspin .vertical-spin .input-group .form-control {
    width: 45px;
    padding: 8px 0;
}
.cm-soon h5{
    display: flex;
    justify-content: center;
    align-items: center;
}
.cm-soon img{
    width: 70px;
    margin-left: 20px;
}
.pointer{
    cursor: pointer;
}
.server-type-item{
    border-radius: 0;
}
.server-type-item h5{
    padding: 10px;
    text-align: center;
    min-height: 56px;
    border-bottom: 1px solid lightgray;
}

.region-item{
    border-radius: 0;
}
.region-item h5{
    padding: 15px 0;
    text-align: center;
    margin-bottom: 0;
}
.server-type-item.active{
    background-color: #4361ee;
}
.server-type-item.active h5{
    color: white;
    border-bottom: 1px solid unset;
}
.server-type-item.active p{
    color: white;
}
.img-region{
    width: 56px;
    height: 32px;
    margin-left: 20px;
}
.box-cart-bottom{
    position: fixed;
    bottom: 0;
    z-index: 9;
}
.box-cart-bottom .panel-body{
    box-shadow: 5px 10px 10px 5px gray;
}
.cart-item-bt{
    font-weight: bold;
    color: black;
    padding: 12px 80px 0 0;
}
.cart-item-vl{
    font-size: 18px;
    color: #4361ee;
}
.disk-item h5{
    min-height: unset;
    margin-bottom: 0;
}
.sub-disk{
    display: none;
}
.pricing-table .pricing--norbu .pricing__title{
    padding: 0.5rem;
}
.pricing-table .pricing--norbu .pricing__price {
    padding: 20px 0 0 0;
}
.pricing-table .pricing--norbu .pricing__price{
    font-size: 33px;
}
.size-type-item{
    cursor: pointer !important;
}
.size-type-item.active{
    background-color: #4361ee;
    color: white;
}
.size-type-item.active h3{
    color: white !important;
}
.size-type-item.active .pricing__item:hover{
    color: white !important;
}
.pricing-table .pricing--norbu .pricing__item.active:hover {
    color: white;
    border: 1px solid #4361ee;
}
.pricing__title::after {
    background: #4361ee;
}
.ct-wp-create{
    margin-bottom: 150px;
}
.ip-wp-create{
    text-align: center !important;
    color: black !important;
    font-weight: bold !important;
}
input[readonly]{
    text-align: center !important;
    color: black !important;
    font-weight: bold !important;
}
@media (max-width: 1500px) {
    .cart-item-bt{
        font-size: 14px !important;
    }
    .cart-item-vl{
        font-size: 14px;
        color: #4361ee;
    }
    .cart-item-bt{
        padding: 12px 10px 0 0;
    }
    .pricing-table .pricing--norbu .pricing__title {
        font-size: 22px;
    }
    .pricing-table .pricing--norbu .pricing__price{
        font-size: 28px;
        padding: 15px;
    }
    .pricing-table .pricing--norbu .pricing__feature-list {
        padding: 1em 0;
    }
}
@media (max-width: 768px) {
    .sub-disk{
        display: block;
    }
    .title-disk-free{
        display: block !important;
    }
    .title-server-type{
        display: none;
    }
    .server-type-item h5{
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        padding: 12px 10px;
        align-items: center;
    }
    .disk-item h5{
        text-align: center !important;
    }
    .cart-item-bt{
        display: none;
    }
    .box-cart-bottom .panel-body div{
        justify-content: center;
    }
    .ct-wp-create{
        margin-bottom: 100px;
    }
}

/*---------signup-step-------------*/
.bg-color{
    background-color: #333;
}
.signup-step-container{
    padding: 150px 0px;
    padding-bottom: 60px;
}




.wizard .nav-tabs {
    position: relative;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    border-bottom-color: transparent;
}

.wizard > div.wizard-inner {
    position: relative;
    margin-bottom: 50px;
    text-align: center;
}

.connecting-line {
    height: 2px;
    background: #e0e0e0;
    position: absolute;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 15px;
    z-index: 1;
}

.wizard .nav-tabs > li.active > a, .wizard .nav-tabs > li.active > a:hover, .wizard .nav-tabs > li.active > a:focus {
    color: #555555;
    cursor: default;
    border: 0;
    border-bottom-color: transparent;
}

span.round-tab {
    width: 50px;
    height: 50px;
    line-height: 30px;
    display: inline-block;
    border-radius: 50%;
    background: #fff;
    z-index: 2;
    margin-top: -8px;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 18px;
    color: #0e214b;
    padding: 10px;
    font-weight: 500;
    border: 1px solid #ddd;
}
span.round-tab i{
    color:#555555;
}
.wizard li.active span.round-tab {
    background: #0db02b;
    color: #fff;
    border-color: #0db02b;
}
.wizard li.active span.round-tab i{
    color: #5bc0de;
}
.wizard .nav-tabs > li.active > a i{
    color: #0db02b;
}

.wizard .nav-tabs > li {
    width: 0%;
}

.wizard li:after {
    content: " ";
    position: absolute;
    left: 46%;
    opacity: 0;
    margin: 0 auto;
    bottom: 0px;
    border: 5px solid transparent;
    border-bottom-color: red;
    transition: 0.1s ease-in-out;
}



.wizard .nav-tabs > li a {
    width: 30px;
    height: 30px;
    margin: 20px auto;
    border-radius: 100%;
    padding: 0;
    background-color: transparent;
    position: relative;
    top: 0;
}
.wizard .nav-tabs > li a i{
    position: absolute;
    top: -15px;
    font-style: normal;
    white-space: nowrap;
    left: 50%;
    transform: translate(-25%, -100%);
    font-size: 16px;
    font-weight: 700;
    color: #000;
}

.wizard .nav-tabs > li a:hover {
    background: transparent;
}

.wizard .tab-pane {
    position: relative;
    padding-top: 20px;
}


.wizard h3 {
    margin-top: 0;
}
.prev-step,
.next-step{
    font-size: 13px;
    padding: 8px 24px;
    border: none;
    border-radius: 4px;
    margin-top: 30px;
}
.next-step{
    background-color: #0db02b;
}
.skip-btn{
    background-color: #cec12d;
}
.step-head{
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 20px;
}
.term-check{
    font-size: 14px;
    font-weight: 400;
}
.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 40px;
    margin-bottom: 0;
}
.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 40px;
    margin: 0;
    opacity: 0;
}
.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 40px;
    padding: .375rem .75rem;
    font-weight: 400;
    line-height: 2;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}
.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 38px;
    padding: .375rem .75rem;
    line-height: 2;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 .25rem .25rem 0;
}
.footer-link{
    margin-top: 30px;
}
.all-info-container{

}
.list-content{
    margin-bottom: 10px;
}
.list-content a{
    padding: 10px 15px;
    width: 100%;
    display: inline-block;
    background-color: #f5f5f5;
    position: relative;
    color: #565656;
    font-weight: 400;
    border-radius: 4px;
}
.list-content a[aria-expanded="true"] i{
    transform: rotate(180deg);
}
.list-content a i{
    text-align: right;
    position: absolute;
    top: 15px;
    right: 10px;
    transition: 0.5s;
}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: #fdfdfd;
}
.list-box{
    padding: 10px;
}
.signup-logo-header .logo_area{
    width: 200px;
}
.signup-logo-header .nav > li{
    padding: 0;
}
.signup-logo-header .header-flex{
    display: flex;
    justify-content: center;
    align-items: center;
}
.list-inline li{
    display: inline-block;
}
.pull-right{
    float: right;
}
.step-create{
    width: 50%;
    margin: 100px auto;
}

@media (max-width: 767px){
    .sign-content h3{
        font-size: 40px;
    }
    .signup-logo-header .navbar-toggle{
        margin: 0;
        margin-top: 8px;
    }
    .signup-logo-header .logo_area{
        margin-top: 0;
    }
    .signup-logo-header .header-flex{
        display: block;
    }
    .step-create{
        width: 100%;
    }
    span.round-tab {
        width: 35px;
        height: 35px;
        font-size: 14px;
        padding: 2px;
    }
    .wizard .nav-tabs > li a i {
        font-size: 12px;
    }
}

</style>
