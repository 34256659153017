<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/">Home</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page"><span>Hosting</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
        <div class="row layout-top-spacing">
            <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
                <div class="panel p-0">
                    <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">
                        <div class="statbox panel box box-shadow">
                            <div class="panel-heading">
                                <div class="d-flex justify-content-between">
                                    <h4>Hosting</h4>
                                    <router-link to="/hosting/create" tag="button" class="btn btn-primary">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-plus"
                                        >
                                            <line x1="12" y1="5" x2="12" y2="19"></line>
                                            <line x1="5" y1="12" x2="19" y2="12"></line>
                                        </svg>
                                        New Hosting
                                    </router-link>
                                </div>
                            </div>
                            <div class="panel-body">
                                <div class="table-responsive">
                                    <table role="table" aria-busy="false" aria-colcount="5" class="table b-table table-bordered" id="__BVID__328">
                                        <thead role="rowgroup" class="">
                                        <tr role="row" class="">
                                            <th role="columnheader" scope="col" aria-colindex="1" class=""><div>#</div></th>
                                            <th role="columnheader" scope="col" aria-colindex="2" class=""><div>Name</div></th>
                                            <th role="columnheader" scope="col" aria-colindex="4" class=""><div>Size</div></th>
                                            <th role="columnheader" scope="col" aria-colindex="4" class=""><div>Disk</div></th>
                                            <th role="columnheader" scope="col" aria-colindex="4" class=""><div>Created At</div></th>
                                            <th role="columnheader" scope="col" aria-colindex="4">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody role="rowgroup">
                                        <tr v-for="(item, i) in listWordPress" :key="id" role="row" class="">
                                            <td aria-colindex="1" role="cell" class="">{{ i + 1 }}</td>
                                            <td aria-colindex="2" role="cell" class="">{{ item.name }}</td>
                                            <td aria-colindex="2" role="cell" class="">{{ item.cpu }}CPU / {{ item.ram / 1000 }}GB</td>
                                            <td aria-colindex="2" role="cell" class="">{{ item.disk /1024 }}GB</td>
                                            <td aria-colindex="2" role="cell" class="">{{ formatCreatedAt(item.createdAt) }}</td>
                                            <td aria-colindex="2" role="cell" >
                                                <router-link :to="'/wordpress/' + item.id">
                                                    <button class="btn btn-info">VIEW</button>
                                                </router-link>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';

import { useMeta } from '@/composables/use-meta';
useMeta({ title: 'Transaction Log' });

//table 1
const columns = ref(['name', 'position', 'office', 'age', 'start_date', 'salary']);
const items = ref([]);
const table_option = ref({
    perPage: 10,
    perPageValues: [5, 10, 20, 50],
    skin: 'table',
    pagination: { nav: 'scroll', chunk: 5 },
    texts: {
        count: 'Showing {from} to {to} of {count}',
        filter: '',
        filterPlaceholder: 'Searcdate...',
        limit: 'Results:',
    },
    resizableColumns: false,
    sortable: ['name', 'position', 'office', 'age', 'start_date', 'salary'],
    sortIcon: {
        base: 'sort-icon-none',
        up: 'sort-icon-asc',
        down: 'sort-icon-desc',
    },
    hiddenColumns: [],
});

//table 2
const items1 = ref([]);

//table 3
const items2 = ref([]);

onMounted(() => {
    bind_data();
});

const bind_data = () => {

};

const capitalize = (text) => {
    return text
        .replace('_', ' ')
        .replace('-', ' ')
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
};

const show_hide_columns = (column, value) => {
    if (!table_option.value.hiddenColumns.includes(column) && !value) {
        table_option.value.hiddenColumns.push(column);
    } else {
        table_option.value.hiddenColumns = table_option.value.hiddenColumns.filter((d) => d !== column);
    }
};
</script>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "HostingList",
    data() {
        return {
            listWordPress: [],
        }
    },
    created() {
        this.getWordPress().then(res => {

            console.log('res getWordPress', res)
            this.listWordPress = res.data.result
        }).catch(e => {
            console.log(e)
        })
    },
    computed:{
        ...mapGetters(['getterMe','getterCurrency'])
    },
    methods: {
        ...mapActions(['getWordPress']),
        convertAmountTransaction(x) {
            return parseInt(x).toLocaleString('vi-VN')
        },
        convertCurrency(x) {
            return x.toLocaleString('vi-VN')
        },
        formatCreatedAt(date){
            date = new Date(date);
            let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s;
        }
    },
}
</script>

<style scoped>
.table > tbody > tr > td {
    padding: 13px;
}
</style>
