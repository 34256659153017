<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/">Home</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page"><span>DNS</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
        <div class="row layout-top-spacing">
            <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
                <div class="panel p-0">
                    <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">
                        <div class="statbox panel box box-shadow">
                            <div class="panel-heading">
                                <div class="d-flex justify-content-between">
                                    <h4>Danh sách tên miền</h4>
                                    <button class="btn btn-primary"  
                                            data-bs-toggle="modal"
                                            data-bs-target="#modalAddDNS">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-plus"
                                        >
                                            <line x1="12" y1="5" x2="12" y2="19"></line>
                                            <line x1="5" y1="12" x2="19" y2="12"></line>
                                        </svg>
                                        Tạo tên miền
                                    </button>
                                </div>
                            </div>
                            <div class="panel-body">
                                <div class="table-responsive">
                                    <table role="table" aria-busy="false" aria-colcount="5" class="table b-table table-bordered" id="__BVID__328">
                                        <thead role="rowgroup" class="">
                                        <tr role="row" class="">
                                            <th role="columnheader" scope="col" aria-colindex="1" class=""><div>Name</div></th>
<!--                                            <th role="columnheader" scope="col" aria-colindex="1" class=""><div>Name Server</div></th>-->
                                            <th role="columnheader" scope="col" aria-colindex="2" class=""><div>Trạng thái</div></th>
                                            <th role="columnheader" scope="col" aria-colindex="4" class=""><div>Ngày tạo</div></th>
                                            <th role="columnheader" scope="col" aria-colindex="4" class=""><div></div></th>
                                        </tr>
                                        </thead>
                                        <tbody role="rowgroup">
                                        <tr v-for="(item, i) in listDNS" :key="id" role="row" class="">
                                            <td aria-colindex="2" role="cell" class="">
                                                <a style="color: #4361ee;font-weight: bold" href="#">
                                                    <i class="fas fa-link"></i> {{ item.name }}
                                                </a>
                                            </td>
<!--                                            <td>-->
<!--                                               <span class="font-weight-bold" v-for="ns in item.nameserver">-->
<!--                                                    {{ ns }} <br>-->
<!--                                               </span>-->
<!--                                            </td>-->
                                            <td v-if="item.active" aria-colindex="2" role="cell">
                                                <span class="badge badge-success badge-active-dns">Activated</span>
                                            </td>
                                            <td v-if="!item.active" aria-colindex="2" role="cell" class="text-warning">
                                                <span class="badge badge-warning badge-active-dns">Not Activated</span>
                                            </td>
                                            <td aria-colindex="2" role="cell" class="">
                                                {{ formatCreatedAt(item.createdAt) }}
                                            </td>
                                            <td aria-colindex="2" role="cell" class="">
                                                <a v-if="!item.active"
                                                   data-bs-toggle="modal"
                                                   data-bs-target="#modalAlertNotActive"
                                                   href="javascript:void(0);"
                                                   :data-bs-domain-name="item.name"
                                                   :data-bs-domain-id="item.id"
                                                   :data-bs-name-sever="item.nameserver.join(',')"
                                                   class="dropdown-item btn btn-warning w-50">
                                                    <i class="flaticon-home-fill-1 me-1"></i>Kích hoạt
                                                </a>

                                                <a v-if="item.active"
                                                   @click="redirectDNSRecord(item)"
                                                   class="dropdown-item btn btn-info w-50">
                                                    <i class="flaticon-home-fill-1 me-1"></i>Chi tiết
                                                </a>

                                                <a
                                                   style="width: 70px;"
                                                   data-bs-toggle="modal"
                                                   data-bs-target="#modalConfirmDeleteDNS"
                                                   href="javascript:void(0);"
                                                   :data-bs-domain-name="item.name"
                                                   :data-bs-domain-id="item.id"
                                                   class="dropdown-item btn btn-danger w-25 mx-2">
                                                    <i class="flaticon-home-fill-1 me-1"></i>Xoá
                                                </a>

                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel p-0 mt-3">
                    <div  class="col-lg-12 col-12 layout-spacing">
                        <div class="statbox panel box box-shadow">
                            <div class="panel-heading">
                                <div class="d-flex justify-content-between">
                                    <h4>Hướng dẫn sử dụng dịch vụ DNS</h4>
                                </div>
                            </div>
                            <div class="panel-body">
                                <router-link class="text-theme d-block p-2" tag="a" to="">
                                    Giới thiệu dịch vụ DNS
                                </router-link>
                                <router-link class="text-theme d-block p-2" tag="a" to="">
                                    Hướng dẫn sử dụng các bản ghi
                                </router-link>
                                <router-link class="text-theme d-block p-2" tag="a" to="">
                                    Ticket hỗ trợ
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal animated fadeInDown" id="modalConfirmDeleteDNS" tabindex="-1" role="dialog" aria-labelledby="modalConfirmDeleteDNS" aria-hidden="true">
            <div style="max-width: 800px" class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalConfirmDeleteDNSLabel">Cảnh báo</h5>
                        <button type="button" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close" class="btn-close"></button>
                    </div>
                    <input type="hidden" id="dnsDeleteIP">
                    <div class="modal-body">
                        <div class="row justify-content-between m-0">
                            <div class="form-group">
                                <h5>Bạn có chắc chắn muốn xoá tên miền <span class="font-weight-bold" id="dnsNameDelete"></span></h5>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn" data-dismiss="modal" data-bs-dismiss="modal"><i class="flaticon-cancel-12"></i> Huỷ</button>
                        <button type="button" class="btn btn-primary" @click="deleleDNS()">Xác Nhận</button>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="modal animated fadeInDown" id="modalAddDNS" tabindex="-1" role="dialog" aria-labelledby="modalAddDNS" aria-hidden="true">
            <div style="max-width: 800px" class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalUpdateDiskLabel">Tạo tên miền</h5>
                        <button type="button" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close" class="btn-close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row justify-content-between m-0">
                            <div class="form-group">
                                <p>Nhập tên miền</p>
                                <input type="text" id="dnsName" class="form-control" placeholder="example.com" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn" data-dismiss="modal" data-bs-dismiss="modal"><i class="flaticon-cancel-12"></i> Huỷ</button>
                        <button type="button" class="btn btn-primary" @click="handleAddDNS()">Xác Nhận</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal animated fadeInDown" id="modalAlertNotActive" tabindex="-1" role="dialog" aria-labelledby="modalAlertNotActive" aria-hidden="true">
            <div style="max-width: 800px" class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalAlertNotActiveLabel">Kích hoạt domain</h5>
                        <button type="button" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close" class="btn-close"></button>
                    </div>
                    <div class="modal-body">
                        <input type="hidden" id="domainNameCurrentSelected">
                        <div class="alert alert-light-danger alert-dismissible border-0 mb-4" role="alert">
                            <strong>Thông báo!</strong> Domain của bạn chưa được kích hoạt trên DNS
                        </div>
                        <h4>Bản ghi NS hiện tại của domain 
                            <span class="font-wright-bold;" style="color: #4361ee" id="domain-current-not-active">
                                
                            </span>
                        </h4>
                        <p>(Quá trình cập nhật có thể mất tới 24h do cache trên hệ thống DNS)</p>
                        <h4>Để kích hoạt, vui lòng trỏ bản ghi NS về :</h4>
                        <div class="list-ns-current mb-3 mt-2">
                            
                        </div>
                        <p>
                            Bạn sẽ nhận được thông báo ngay khi domain của bạn thay đổi trạng thái .Bạn cũng có thể cập nhật trạng thái
                            kích hoạt mới nhất của domain bằng cách bấm nút bên dưới
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn" data-dismiss="modal" data-bs-dismiss="modal"><i class="flaticon-cancel-12"></i> Đóng</button>
                        <a @click="checkDomain()" type="button" class="btn btn-primary">
                            Kiểm Tra
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useMeta } from '@/composables/use-meta';
useMeta({ title: 'DNS Server' });
</script>
<script>
import {mapActions, mapGetters} from "vuex";
import $ from 'jquery'
export default {
    name: "DNSList",
    data() {
        return {
            listDNS: [],
        }
    },
    created() {
        this.getDNSList().then(res => {
            this.listDNS = res.data.result.data
        }).catch(e => {
            console.log(e)
        })
    },
    mounted() {
        let modalAlertNotActive = document.getElementById('modalAlertNotActive')
        modalAlertNotActive.addEventListener('show.bs.modal', function (event) {
            // Button that triggered the modal
            var button = event.relatedTarget
            // Extract info from data-bs-* attributes
            var domainName = button.getAttribute('data-bs-domain-name')
            var domainId = button.getAttribute('data-bs-domain-id')
            var nameServer = button.getAttribute('data-bs-name-sever').split(',')
            
            let listNS = '';
            for (let i = 0; i < nameServer.length; i++) {
                listNS += `<p class="font-weight-bold" style="color: black">${i + 1}. ${nameServer[i]}</p>`
            }
            modalAlertNotActive.querySelector('.list-ns-current').innerHTML =  listNS
            
            var modalTitle = modalAlertNotActive.querySelector('#domain-current-not-active')

            modalTitle.innerHTML = domainName
            $("#domainNameCurrentSelected").val(domainId)
        })
        
        // delete DNS
        let modalAlertDeleteDNS = document.getElementById('modalConfirmDeleteDNS')
        modalAlertDeleteDNS.addEventListener('show.bs.modal', function (event) {
            // Button that triggered the modal
            var button = event.relatedTarget
            // Extract info from data-bs-* attributes
            var domainName = button.getAttribute('data-bs-domain-name')
            var domainId = button.getAttribute('data-bs-domain-id')
            
            var modalTitle = modalAlertDeleteDNS.querySelector('#dnsNameDelete')

            modalTitle.innerHTML = domainName
            $("#dnsDeleteIP").val(domainId)
        })
    },
    computed:{
        ...mapGetters(['getterMe','getterCurrency'])
    },
    methods: {
        ...mapActions(['getDNSList','createDNS','DNScheck','deleteDNSAPI']),
        convertAmountTransaction(x) {
            return parseInt(x).toLocaleString('vi-VN')
        },
        convertCurrency(x) {
            return x.toLocaleString('vi-VN')
        },
        formatCreatedAt(date){
            date = new Date(date);
            let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s;
        },
        deleleDNS(){
            let dnsID = $("#dnsDeleteIP").val()
            this.deleteDNSAPI(dnsID).then(res => {
                if (res && res.data.message === "success"){
                    this.$store.dispatch('showNotification',{
                        title: `Success !`,
                        type: 'success',
                        text: `Delete domain success !`,
                    })
                    setTimeout(function (){
                        window.location.reload()
                    },1000)
                }
                else{
                    this.$store.dispatch('showNotification',{
                        title: 'Error !',
                        type: 'error',
                        text: 'Fail to delete domain!',
                    })
                }
            }).catch(error => {
                console.log(error)
                this.$store.dispatch('showNotification',{
                    title: 'Error !',
                    type: 'error',
                    text: error.response.data.message,
                })
                return false
            })
        },
        handleAddDNS(){
            let _dnsName = $("#dnsName").val()
            if (_dnsName === '') return false;
            
            let domainName = _dnsName.replace('https://','')
                            .replace('http://','')
                            .replace('https://www.','')
            
            this.createDNS({name: domainName}).then(res => {
                if (res && res.data.message === "success"){
                    this.$store.dispatch('showNotification',{
                        title: `Success !`,
                        type: 'success',
                        text: `Add domain success !`,
                    })
                    setTimeout(function (){
                        window.location.href = '/dns'
                    },1000)
                }
                else{
                    this.$store.dispatch('showNotification',{
                        title: 'Error !',
                        type: 'error',
                        text: 'Fail to add domain!',
                    })
                }
            }).catch(error => {
                console.log(error)
                this.$store.dispatch('showNotification',{
                    title: 'Error !',
                    type: 'error',
                    text: error.response.data.message,
                })
                return false
            })
        },
        checkDomain(){
            this.DNScheck($("#domain-current-not-active").text()).then(res => {
                if (res && res.data.message === "success" && res.data.result.isValid){
                    this.$store.dispatch('showNotification',{
                        title: `Success !`,
                        type: 'success',
                        text: `Add domain success !`,
                    })
                    this.$router.push({
                        name:'DomainRecordList',
                        params:{
                            id: $("#domainNameCurrentSelected").val(),
                        },
                    })
                }
                else{
                    this.$store.dispatch('showNotification',{
                        title: 'Error !',
                        type: 'error',
                        text: 'The domain name is not pointing correctly',
                    })
                }
            }).catch(error => {
                console.log(error)
                this.$store.dispatch('showNotification',{
                    title: 'Error !',
                    type: 'error',
                    text: error.response.data.message,
                })
                return false
            })
        },
        redirectDNSRecord(obj){
            this.$router.push({
                name:'DomainRecordList',
                params:{
                    id: obj.id,
                    data: obj
                },
            })
            return 1;
        }
    },
}
</script>

<style scoped>
.badge-active-dns{
    width: 100px;
}
.table > tbody > tr > td {
    padding: 13px;
    text-align: center;
}
th{
    text-align: center;
}
</style>
