<template>
    <div class="m-auto position-relative">
        <div class="card m-auto ct-sc">
            <div class="card-body text-center m-auto" style="width: 95%;">
                <img style="margin: auto; width: 100px" :src="require('@/assets/images/success.png')" alt=""/>
                <h4 class="title-sc font-weight-bold">Giao dịch thanh toán thành công</h4>
            </div>
            <router-link class="m-auto py-4 d-block" style="text-align: center;color: #4361ee" to="/">
                Về trang chủ
            </router-link>

        </div>

    </div>
</template>

<script setup>
import '@/assets/sass/authentication/auth-boxed.scss';
import {useMeta} from '@/composables/use-meta';

useMeta({title: 'Order Success'});
</script>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'StripeSuccess',
    data() {
        return {
          
        }
    },
    computed: {
        ...mapGetters(['getterCurrency'])
    }
}
</script>
<style scoped>
.ct-sc {
    width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 25%);
}

.title-sc {
    font-weight: bold;
    padding: 10px;
}

.font-weight-bold {
    font-weight: bold;
}

@media (max-width: 768px) {
    .ct-sc {
        width: 85%;
    }
}
</style>
