<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/">Home</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page"><span>Payment</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>

        <div class="row layout-spacing">
            <div class="col-12 layout-top-spacing">
                <div class="bio layout-spacing">
                    <div class="panel">
                        <div class="panel-body">
                            <h3 class="balance">Wallet Balance: <b>{{ convertCurrency(getterMe.balance * 1) }} {{getterCurrency.lower}}</b></h3>
                            <div class="bio-skill-box pt-0 price-box">
                                <div>
                                    <p style="font-size: 17px">Chọn số tiền muốn nạp:</p>
                                </div>
                                <div class="row">
                                    <div v-for="(item,i) in listPrice"
                                         class="deno-item col-6 col-xl-3 col-lg-3 mb-lg-4 mb-3"
                                         :class="priceActive === i ? 'active' : ''"
                                         @click="setPriceActive(i,item.value)"
                                    >
                                        <div class="d-flex b-skills">
                                            <div></div>
                                            <div class="text-center">
                                                <h4 class="text-center">{{ item.text }}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <input id="price_payment" :value="amount" type="hidden" />
                                    <div
                                         class="deno-item col-6 col-xl-3 col-lg-3 mb-lg-4 mb-3"
                                         :class="priceActive === 7 ? 'active' : ''"
                                         @click="setPriceActive(7,0)"
                                    >
                                        <div class="d-flex b-skills " style="padding: 7px">
                                            <div></div>
                                            <div class="text-center position-relative">
                                                <input v-model="amount" type="number" class="form-control free_price" placeholder="________" />
                                                <span id="currency">{{ getterCurrency.lower }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="amount > 0" class="bio-skill-box pt-0 price-box">
                                <div>
                                    <p style="font-size: 17px">Chọn phương thức thức thanh toán</p>
                                </div>
                                <div class="row">
                                    <div class="deno-item col-6 col-xl-3 col-lg-3 mb-lg-4 mb-3"
                                         :class="paymentTypeIndex === 1 ? 'active' : ''"
                                         @click="setpaymentTypeIndex(1)"
                                    >
                                        <div class="d-flex b-skills">
                                            <div></div>
                                            <div class="text-center">
                                                <h4 class="text-center">Local Payment ({{getterRegionCode}})</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="deno-item col-6 col-xl-3 col-lg-3 mb-lg-4 mb-3"
                                         :class="paymentTypeIndex === 2 ? 'active' : ''"
                                         @click="setpaymentTypeIndex(2)">
                                        <div class="d-flex b-skills">
                                            <div></div>
                                            <div class="text-center">
                                                <h4 class="text-center">Credit Card</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="amount > 0 && paymentTypeIndex == 1" class="bio-skill-box pt-0 payment-type-box">
                                <div>
                                    <p style="font-size: 17px">Chọn hình thức thanh toán</p>
                                </div>
                                <div class="row">
                                    <div v-for="(item,i) in getListPaymentType"
                                         class="deno-item col-12 col-lg-4 col-md-6 mb-lg-4 mb-3"
                                         :class="paymentTypeActive === i ? 'active' : ''"
                                         @click="setPaymentTypeActive(i,item.value)"
                                    >
                                        <div class="d-flex b-skills box-pay">
                                            <div></div>
                                            <div class="text-center pay-item">
                                                <h4 class="text-center">{{ item.text }}</h4>
                                                <img :src="require('@/assets/images/' + item.img)" :style="'width:' + item.width" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="box-payment-card">
                                <!--            FORM SQUARE                    -->
                                <div class="row div-input-checkout"
                                     :class="{ 'd-block': paymentTypeIndex == 1 && getterRegionCode !== 'VN', 
                                                'd-none': paymentTypeIndex != 1 || getterRegionCode === 'VN' }">
                                    <form id="payment-form">
                                        <div id="card-container"></div>
                                        <button class="btn btn-info w-100 mb-4 me-2 btn-checkout" id="card-button" type="button">
                                            Thanh toán by EPIS &nbsp;
                                            <span v-if="amount > 0">
                                                {{ convertCurrency(parseInt(amount)) }} {{getterCurrency.lower}}
                                            </span>
                                        </button>
                                    </form>
                                    <div id="payment-status-container"></div>
                                </div>
                                <!--         END FORM SQUARE                    -->
                                
                                <!--            FORM PAYMENT STRIPE                    -->
                              
                                <div class="row div-input-checkout mb-5 pb-5"
                                     :class="{ 'd-block': paymentTypeIndex == 2, 
                                                'd-none': paymentTypeIndex != 2 }"
                                >
                                    <p class="text-left" style="text-align: left">Thẻ thanh toán</p>
                                    <div @click="()=>{ isChooseCreditCard = true}" class="card credit-card-item" :class="{active: isChooseCreditCard}">
                                        <div class="card-body d-flex justify-content-between align-items-center">
                                            <img class="icon-credit-card" style="width: 80px;" :src="genImageCreditCard(methodLinkCard.card.brand)" alt="">
                                            <div>
                                                <h5 class="card-title" style="text-transform: capitalize">{{ methodLinkCard.card.brand }} **** {{methodLinkCard.card.last4}}</h5>
                                                <p class="card-text">Expires {{ methodLinkCard.card.exp_month }}/{{ methodLinkCard.card.exp_year }}</p>
                                            </div>
                                            <div class="me-2 custom-dropdown btn-group">
                                                <a class="btn dropdown-toggle btn-icon-only" href="#" role="button" id="pendingTask" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        style="width: 24px; height: 24px"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        class="feather feather-more-vertical"
                                                    >
                                                        <circle cx="12" cy="12" r="1"></circle>
                                                        <circle cx="12" cy="5" r="1"></circle>
                                                        <circle cx="12" cy="19" r="1"></circle>
                                                    </svg>
                                                </a>
                                                <ul class="dropdown-menu" aria-labelledby="pendingTask">
                                                    <li>
                                                        <a @click="attachMethodPayment()" href="javascript:void(0);" class="dropdown-item"><i class="flaticon-home-fill-1 me-1"></i>Set default</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="m-auto">
                                        <button :disabled="!isChooseCreditCard" @click="confirmCreditCard()" style="padding: 14px;font-size: 16px" class="btn btn-primary px-0 mt-5 w-100">Xác nhận thanh toán</button>
                                    </div>
                                </div>
                                <!--         FORM PAYMENT STRIPE                     -->
                                
                                <!--              BUTTON PAYMENT                  -->
                                <div class="row div-input-checkout d-flex flex-row justify-content-around" v-if="paymentTypeIndex == 1 && amount > 0 && paymentMethod > 1">
                                    <button class="btn btn-info btn-checkout" @click="checkout()" type="button">
                                        Thanh toán &nbsp; 
                                        <span v-if="amount > 0">
                                           {{ convertCurrency(parseInt(amount)) }} {{getterCurrency.lower}}
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="modalPayMe" tabindex="-1" role="dialog" aria-labelledby="modalPayMeLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <iframe :src="checkoutURLPayMe" :title="checkoutURLPayMe"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import '@/assets/sass/scrollspyNav.scss';
import '@/assets/sass/users/user-profile.scss';
</script>
<script>
import {mapActions, mapGetters} from "vuex";
import $ from 'jquery'
export default {
    name: "Payment",
    data() {
        return {
            paymentTypeIndex: 0,
            loading: false,
            amount: '________',
            priceActive: -1,
            paymentTypeActive: -1,
            showFormInputPayment: false,
            paymentMethod: 1,
            paymentType: [
                {
                    text: 'Alepay',
                    value: 2,
                    img: 'alego2.png',
                    width: '100px'
                },
                {
                    text: 'VietQR',
                    value: 3,
                    img: 'vietqr2.png',
                    width: '102px'
                },
                {
                    text: 'ATM Nội địa',
                    value: 4,
                    img: 'debit-card-icon-10.jpg',
                    width: '56px'
                },
                {
                    text: 'Thẻ quốc tế',
                    value: 5,
                    img: 'credit-card.png',
                    width: '58px'
                },
                {
                    text: 'VN Pay',
                    value: 6,
                    img: 'vnpay.png',
                    width: '52px'
                },
                {
                    text: 'Credit Card',
                    value: 7,
                    img: 'epispayment.png',
                    width: '94px'
                },
            ],
            methodLinkCard: null,
            priceVND: [
                {
                    text: '100.000đ',
                    value: 100000
                },
                {
                    text: '500.000đ',
                    value: 500000
                },
                {
                    text: '1.000.000đ',
                    value: 1000000
                },
                {
                    text: '2.000.000đ',
                    value: 2000000
                },
                {
                    text: '3.000.000đ',
                    value: 3000000
                },
                {
                    text: '5.000.000đ',
                    value: 5000000
                },
                {
                    text: '10.000.000đ',
                    value: 10000000
                }
            ],
            priceAUD: [
                {
                    text: '5 A$',
                    value: 5
                },
                {
                    text: '10 A$',
                    value: 10
                },
                {
                    text: '20 A$',
                    value: 20
                },
                {
                    text: '30 A$',
                    value: 30
                },
                {
                    text: '40 A$',
                    value: 40
                },
                {
                    text: '50 A$',
                    value: 50
                },
                {
                    text: '60 A$',
                    value: 60
                }
            ],
            modalPayMe: null,
            checkoutURLPayMe: '',
            showFormStripe: false,
            showFormPayme: false,
            methodIDStripe: '',
            isChooseCreditCard: false
        }
    },
    created() {
        this.getListMethod().then(res => {
            if (!res.data.result.length){
                this.$router.push('/link-card')
            }else{
                this.methodLinkCard = res.data.result[0]
                console.log('res.data.result[0]', res.data.result[0])
            }
        }).catch(e => {
            console.log(e)
        })
        
    },
    async mounted() {
        localStorage.setItem('accessToken',sessionStorage.getItem('accessToken'))
        
        this.modalPayMe =  new bootstrap.Modal(document.getElementById('modalPayMe'), {
            keyboard: false,
            backdrop: false,
        })
        
        
        // ============= PAYMENT SQUARE ==================
        const appId = process.env.VUE_APP_PAYMENT_APP_ID
        const locationId = process.env.VUE_APP_PAYMENT_LOCATION_ID
        async function initializeCard(payments) {
            const card = await payments.card()
            await card.attach('#card-container')

            return card
        }
        // verificationToken can be undefined, as it does not apply to all payment methods.
        async function createPayment(token, verificationToken) {
            const bodyParameters = {
                locationId,
                amount:  parseInt($('#price_payment').val()),
                source_id: token,
            }

            if (verificationToken !== undefined) {
                bodyParameters.verification_token = verificationToken
            }

            const body = JSON.stringify(bodyParameters)

            const baseURL = process.env.VUE_APP_API_SERVICE + '/api/payments/square/checkout'
            console.log('body', body)
            const paymentResponse = await fetch(baseURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' +  sessionStorage.getItem('accessToken')
                },
                body
            }).then(res=>{
                console.log('res paymentResponse', res)
            }).catch(e=>{
                console.log('catch paymentResponse', e)
            })

            console.log('paymentResponse', paymentResponse)
            if (paymentResponse.ok) {
                console.log('paymentResponse.json()', paymentResponse.json())
                return paymentResponse.json()
            }

            const errorBody = await paymentResponse.text()
            throw new Error(errorBody)
        }
        // @ts-ignore: Unreachable code error
        async function tokenize(paymentMethod) {
            const tokenResult = await paymentMethod.tokenize()
            if (tokenResult.status === 'OK') {
                return tokenResult.token
            } else {
                throw new Error(`Tokenization errors: ${JSON.stringify(tokenResult.errors)}`)
            }
        }

        // status is either SUCCESS or FAILURE;
        function displayPaymentResults(status) {
            const statusContainer = document.getElementById('payment-status-container')
            if (status === 'SUCCESS') {
                statusContainer.classList.remove('is-failure')
                statusContainer.classList.add('is-success')
            } else {
                statusContainer.classList.remove('is-success')
                statusContainer.classList.add('is-failure')
            }
            statusContainer.style.visibility = 'visible'
        }
        async function verifyBuyer(payments, token) {
            const verificationDetails = {
                amount: '1.00',
                billingContact: {
                    addressLines: ['123 Main Street', 'Apartment 1'],
                    familyName: 'Doe',
                    givenName: 'John',
                    email: 'jondoe@gmail.com',
                    country: 'GB',
                    phone: '3214563987',
                    region: 'LND',
                    city: 'London'
                },
                currencyCode: 'GBP',
                intent: 'CHARGE'
            }

            const verificationResults = await payments.verifyBuyer(token, verificationDetails)
            return verificationResults.token
        }
        if (!window.Square) {
            throw new Error('Square.js failed to load properly')
        }

        let payments
        try {
            payments = window.Square.payments(appId, locationId)
        } catch {
            const statusContainer = document.getElementById('payment-status-container')
            statusContainer.className = 'missing-credentials'
            statusContainer.style.visibility = 'visible'
            return
        }
        let card
        try {
            card = await initializeCard(payments)
        } catch (e) {
            console.error('Initializing Card failed', e)
            return
        }

        async function handlePaymentMethodSubmission(event, paymentMethod, shouldVerify = false) {
            event.preventDefault()

            try {
                // disable the submit button as we await tokenization and make a payment request.
                cardButton.disabled = true
                const token = await tokenize(paymentMethod)
                let verificationToken

                if (shouldVerify) {
                    verificationToken = await verifyBuyer(payments, token)
                }

                const paymentResults = await createPayment(token, verificationToken)

                displayPaymentResults('SUCCESS')
                console.debug('Payment Success', paymentResults)
            } catch (e) {
                cardButton.disabled = false
                displayPaymentResults('FAILURE')
                console.error(e.message)
            }
        }

        const cardButton = document.getElementById('card-button')
        // eslint-disable-next-line require-await
        cardButton.addEventListener('click', async function (event) {
            // SCA only needs to be run for Card Payments. All other payment methods can be set to false.
            await handlePaymentMethodSubmission(event, card, true)
        })

        // ============= END  PAYMENT SQUARE ==================



        // ============= PAYMENT  STRIPE ==================
    },
    computed:{
        ...mapGetters(['getterMe','getterCurrency','getterRegionCode']),
        listPrice(){
            switch(this.getterRegionCode) {
                case "VN":
                    return this.priceVND
                case "AUD":
                   return this.priceAUD
                default:
                // code block
            }
        },
        getListPaymentType(){
            if (this.getterRegionCode === 'VN'){
                // vietnam không dùng square
                return this.paymentType.filter(function (element) {
                    return element.value < 7
                });
            }else{
                // aud, usd, euro chỉ dùng square
                return this.paymentType.filter(function (element) {
                    return element.value === 7
                });
            }
        }
    },
    methods: {
        ...mapActions(['getListMethod','payment','checkoutCreditCardAPI','confirmCreditCardAPI','attachMethodAPI']),
        convertAmountTransaction(x) {
            return parseInt(x).toLocaleString('vi-VN')
        },
        convertCurrency(x) {
            return x.toLocaleString('vi-VN')
        },
        setPriceActive(index,value){
            this.priceActive = index
            this.amount = value
        },
        setPaymentTypeActive(index,value){
            this.showFormStripe = false
            this.paymentTypeActive = index
            this.showFormInputPayment = false
            this.paymentMethod = value
            if (index === 5) {
                this.showFormInputPayment = true
            } else {
            }
        },
        formatCreatedAt(date){
            date = new Date(date);
            let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s;
        },
        payCreditCard(){
            this.showFormStripe = true;
            this.showFormPayme = false
        },
        checkout() {
            this.loading = true
            let orderType = 2
            let orderMethod = null
            // ================= ALEPAY , type = 1, còn lại type = 2
            if (this.paymentMethod == 2) {
                orderType = 1
            }
            if (this.paymentMethod == 3) {
                orderMethod = 'VIETQR'
            }
            if (this.paymentMethod == 4) {
                orderMethod = 'ATMCARD'
            }
            if (this.paymentMethod == 5) {
                orderMethod = 'CREDITCARD'
            }
            if (this.paymentMethod == 6) {
                orderMethod = 'QRPAY'
            }
            if (this.paymentMethod == 7) {
                orderMethod = 'BANKTRANSFER'
            }
            const data = {
                amount: parseInt($('#price_payment').val()),
                type: orderType,
                method: orderMethod
            }

            this.payment(data).then(res => {
                console.log('res payment', res)
                this.loading = false
                if (res.data.statusCode == 200 && res.data.message === 'success'){
                    let url = res.data.result.checkoutUrl
                    if (url.includes('http:')){
                        url = url.replace('http:','https:')
                    }
                    window.location.href = url
                }else{
                    this.$store.dispatch('showNotification',{
                        title: `Error  !`,
                        type: 'error',
                        text: `Please try again !`,
                    })
                }
            }).catch(e => {
                console.log('e', e)
                this.loading = false
                this.$store.dispatch('showNotification',{
                    title: `Error !`,
                    type: 'error',
                    text: `Please try again !`,
                })
            })
        },
        setpaymentTypeIndex(i){
            this.paymentTypeIndex = i
        },
        genImageCreditCard(type){
            switch(type) {
                case 'visa':
                    return require('@/assets/images/v2/visa.png')
                case 'mastercard':
                    return require('@/assets/images/v2/mastercard.png')
                case 'amex':
                    return require('@/assets/images/v2/amex.png')
                case 'jcb':
                    return require('@/assets/images/v2/jcb.jpg')
                default:
                    return require('@/assets/images/v2/visa.png')
            }
           
        },
        attachMethodPayment(){
            this.attachMethodAPI(this.methodLinkCard.id).then(res => {
                console.log('attachMethodAPI', res)
            }).catch(e=>{
                console.log(e)
            })
        },
        confirmCreditCard(){
            this.checkoutCreditCardAPI({amount: parseInt($('#price_payment').val())}).then(res => {
                if(res.data.message === 'success'){
                    let transId = res.data.result.transId
                    let methodId = this.methodLinkCard.id
                    // confirm
                    let objRq = {
                        transId,
                        methodId
                    }
                    this.confirmCreditCardAPI(objRq).then(res => {
                        let clientSecret = res.data.result.client_secret
                        const stripe = Stripe(process.env.VUE_APP_PAYMENT_STRIPE_KEY);
                        var _this = this
                        stripe
                            .confirmCardPayment(clientSecret, {
                                payment_method: methodId,
                            })
                            .then(result=>{
                                console.log('result confirmCardPayment', result)
                                if (result.error != undefined && result.error.type === 'invalid_request_error'){
                                    this.$store.dispatch('showNotification',{
                                        title: `Error  !`,
                                        type: 'error',
                                        text: result.error.message,
                                    })
                                }else if (result.paymentIntent.status === "succeeded"){
                                    this.$store.dispatch('showNotification',{
                                        title: `Thành công!`,
                                        type: 'success',
                                        text: 'Xác nhận thanh toán thành công !',
                                    })
                                }else{
                                    this.$store.dispatch('showNotification',{
                                        title: `Không thành công !`,
                                        type: 'error',
                                        text: 'Có lỗi xảy ra vui lòng thử lại !',
                                    })
                                }
                                // Handle result.error or result.paymentIntent
                            });
                        
                    }).catch(e => {
                        console.log(e)
                        this.$store.dispatch('showNotification',{
                            title: `Error  !`,
                            type: 'error',
                            text: `Fail! Please try again !`,
                        })
                    })
                }else{
                    this.$store.dispatch('showNotification',{
                        title: `Error  !`,
                        type: 'error',
                        text: `Fail! Please try again !`,
                    })
                }
            }).catch(e => {
                this.$store.dispatch('showNotification',{
                    title: `Error  !`,
                    type: 'error',
                    text: `Fail! Please try again !`,
                })
                console.log(e)
            })
        }
    },
}
</script>

<style scoped>
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .icon-credit-card{
        width: 400px;
    }
    .box-payment-card{
        margin: auto;
    }
    
    .credit-card-item{
        width: 400px;
        cursor: pointer;
    }
    .credit-card-item.active{
       border: 2px solid #3d38e1;
    }
    .btn-checkout{
        height: 55px;
        font-size: 18px;
        margin-top: 30px;
        width: 45%;
    }
    .bio .b-skills {
        padding: 20px;
        cursor: pointer;
        justify-content: center;
    }
    .bio .b-skills h4{
        font-weight: bold;
        margin-bottom: 0;
    }
    .deno-item.active .b-skills{
        background: #4361ee;
        transition: transform 2s;
    }
    .deno-item.active h4{
        color: white;
    }
    .free_price{
        border: none;
        width: 174px;
        box-shadow: none !important;
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
        color: #3b3f5c;
    }
    .deno-item.active .free_price{
        background-color: #4361ee;
        color: white;
    }
    #currency{
        position: absolute;
        top: 10px;
        font-size: 1.5rem;
        font-weight: bold;
        right: 0;
        color: #3b3f5c;
    }
    .deno-item.active #currency{
        color: white;
    }
    .deno-item.active ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
    }
    ::selection {
        background: none !important;
        color: unset !important;
    }
    .pay-item{
        width: 100%;
        display: flex;
        padding: 20px 60px;
        justify-content: space-between;
        align-items: center;
        max-height: 75px;
    }
    .pay-item img{
        width: 100px;
    }

    .div-input-checkout {
        margin: auto;
        text-align: center;
        width: 100%;
    }
    @media (max-width: 769px) {
        .icon-credit-card{
            width: 100%;
        }
        .price-box .bio .b-skills {
            padding: 10px;
            cursor: pointer;
            justify-content: center;
        }
        .payment-type-box .pay-item {
            padding: 0 20px;
        }
        .pay-item{
            max-height: 42px;
        }
        .btn-checkout{
            height: 45px;
            font-size: 16px;
            margin-top: 10px;
            width: 100%;
        }
        .div-input-checkout {
            width: 100%;
        }
        .box-payment-card{
            width: 100%;
        }
    }
    @media (max-width: 450px) {
        .pay-item{
            max-height: 20px;
        }
        .payment-type-box .pay-item{
            max-height: 30px !important;
        }
        
        .price-box .b-skills {
            padding: 10px;
        }
        .free_price{
            padding: 3px 0;
        }
        #currency{
            display: none;
        }
        .price-box h4{
            font-size: 16px;
        }
        .price-box .deno-item .free_price {
           font-size: 16px !important;
            width: 150px;
        }
        .payment-type-box h4{
            font-size: 16px;
        }
    }
</style>
