<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/">Home</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page"><span>Project</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
        <div class="row layout-top-spacing">
            <div class="col-12 layout-spacing">
                <div class="panel p-0">
                    <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">
                        <div class="statbox panel box box-shadow">
                            <div class="panel-heading">
                                <div class="d-flex justify-content-between">
                                   <div>
                                       <h4>New Project</h4>
                                       <p class="mt-3">Project Name <span style="color: brown">( lowercase letters, numbers, and "-" only )</span></p>
                                   </div>
                                </div>
                            </div>
                            <div class="panel-body py-0">
                                <div class="mb-4 col-lg-8 col-12 d-flex">
                                    <input v-model="name" type="text" class="form-control w-100" placeholder="ex: project-demo" />
                                </div>
                                <div class="mb-4 col-lg-8 col-12 d-flex">
                                    <button @click="createProject" class="btn btn-secondary">Create Project</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel p-0">
                    <div class="col-lg-12 col-12 layout-spacing">
                        <div class="statbox panel box box-shadow">
                            <div class="panel-heading">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <h4>Connect a repository</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-body py-5 row">
                                <div class="card m-auto py-5 text-center col-lg-6 col-12">
                                    <div class="row card-body d-flex">
                                        <p>Connect your account to Github or Gitlab to begin using your existing repos for new services</p>
                                    </div>
                                    <div>
                                        <button class="btn-connect-git btn btn-primary p-3">
                                            <i class="fa-brands fa-github px-2"></i>
                                            Connect Github</button>
                                    </div>
                                    <div class="mt-3">
                                        <button class="btn-connect-git btn btn-secondary p-3">
                                            <i class="fa-brands fa-gitlab px-2"></i>
                                            Connect Gitlab</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';

import { useMeta } from '@/composables/use-meta';
useMeta({ title: 'Domain Record Add' });
</script>
<script>
import {mapActions, mapGetters} from "vuex";
import $ from 'jquery'
export default {
    name: "ProjectAdd",
    data() {
        return {
            domainDetail: null,
            name: "",
            type: "A",
            content: "",
            ttl: "",
            priority: "",
            proxied: true,
        }
    },
    mounted() {
        $(".modal-backdrop").remove()
    },
    created() {
        this.getDetailDNS(this.$route.params.id).then(res => {
            this.domainDetail = res.data.result
        }).catch(e => {
            console.log(e)
        })
    },
    computed:{
        ...mapGetters(['getterMe','getterCurrency'])
    },
    methods: {
        ...mapActions(['getDetailDNS','addRecordToDomain']),
        convertAmountTransaction(x) {
            return parseInt(x).toLocaleString('vi-VN')
        },
        convertCurrency(x) {
            return x.toLocaleString('vi-VN')
        },
        formatCreatedAt(date){
            date = new Date(date);
            let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s;
        },
        createProject(){
            if (this.name === '' || this.content === '' || this.priority === '' || this.ttl === ''){
                this.$store.dispatch('showNotification',{
                    title: 'Error !',
                    type: 'error',
                    text: 'Please input info record !',
                })
                return false;
            }
            let obj = {
                "id" : this.$route.params.id,
                "name": this.name,
                "type": this.type,
                "content": this.content,
                "ttl": this.ttl,
                "priority": parseInt(this.priority),
                "proxied": this.proxied
            }
            this.addRecordToDomain(obj).then(res => {
                console.log('addRecordToDomain',res)
            }).catch(e => {
                console.log(e)
                return false
            })
        }
    },
}
</script>

<style scoped>
.table > tbody > tr > td {
    padding: 13px;
}
.panel{
    border: none !important;
    background-color: unset !important;
}
.btn-connect-git{
    width: 300px;
}
</style>
