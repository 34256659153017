<template>
    <div class="layout-px-spacing">
        <BreadCrumb module-name="Create WordPress" />
        <div class="row ct-wp-create">
<!--            <div class="col-lg-12 layout-spacing layout-top-spacing">-->
<!--                <div class="statbox panel box box-shadow">-->
<!--                    <div class="card">-->
<!--                        <div class="card-header d-flex align-items-center">-->
<!--                            <img style="width: 30px;" src="@/assets/images/v2/servericon.png" alt="">-->
<!--                            <h6 class="font-weight-bold text-black mb-0 pl-3">Loại máy chủ</h6>-->
<!--                        </div>-->
<!--                        <div class="card-body p-0">-->
<!--                            <div class="row justify-content-between m-0">-->
<!--                                <div class="card col-12 col-md-4 pointer server-type-item p-0">-->
<!--                                    <div class="card-body p-0">-->
<!--                                        <h5 class="card-title font-weight-bold" style="padding: 16px;background-color: #4361ee;color: white">BASIC</h5>-->
<!--                                        <p class="title-server-type card-text text-center my-3">Mauris nisi felis, placerat in volutpat id.</p>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="card col-12 col-md-4 pointer server-type-item p-0 cm-soon">-->
<!--                                    <div class="card-body p-0">-->
<!--                                        <h5 class="card-title font-weight-bold" style="background-color: #4361ee;color: white">PREMIUM-->
<!--                                            <span><img src="@/assets/images/v2/coming.png" alt=""></span>-->
<!--                                        </h5>-->
<!--                                        <p class="title-server-type card-text text-center my-3">Mauris nisi felis, placerat in et sapien.</p>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="card col-12 col-md-4 pointer server-type-item p-0 cm-soon">-->
<!--                                    <div class="card-body p-0">-->
<!--                                        <h5 class="card-title font-weight-bold" style="background-color: #4361ee;color: white">ENTERPRISE-->
<!--                                            <span><img src="@/assets/images/v2/coming.png" alt=""></span>-->
<!--                                        </h5>-->
<!--                                        <p class="title-server-type card-text text-center my-3">Mauris nisi felis, id, varius et sapien.</p>-->
<!--                                    </div>-->
<!--                                </div>-->

<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    -->
<!--                </div>-->
<!--            </div>-->
            <div class="col-lg-12 layout-spacing layout-top-spacing">
                <div class="statbox panel box box-shadow">
                    <div class="card">
                        <div class="card-header d-flex align-items-center">
                            <img style="width: 30px;" src="@/assets/images/v2/servericon.png" alt="">
                            <h6 class="font-weight-bold text-black mb-0 pl-3">Data Center</h6>
                        </div>
                        <div class="card-body p-0">
                            <div class="row justify-content-between m-0">
                                <div v-for="(region, i) in listRegion" :key="i"
                                     :class="{ active: selectedRegion === i }"
                                     @click="setActiveRegion(i, region)"
                                     class="card col-12 col-md-3 pointer region-item server-type-item p-0">
                                    <div class="card-body p-0">
                                        <h5 style="border-bottom: unset" class="card-title font-weight-bold">{{ region.name }}
                                            <span>
                                                <img class="img-region" v-if="i=== 0" src="../../../assets/images/v2/singapore.png" alt="">
                                                <img class="img-region" v-if="i=== 1" src="../../../assets/images/v2/vietnam.jpg" alt="">
                                                <img class="img-region" v-if="i=== 2" src="../../../assets/images/v2/aus.png" alt="">
                                                <img class="img-region" v-if="i=== 3" src="../../../assets/images/v2/usa.png" alt="">
                                                <img class="img-region" v-if="i=== 4" src="../../../assets/images/v2/ger.png" alt="">
                                                <img class="img-region" v-if="i=== 5" src="../../../assets/images/v2/po.png" alt="">
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-12 layout-spacing layout-top-spacing">
                <div class="statbox panel box box-shadow">
                    <div class="card">
                        <div class="card-header d-flex align-items-center">
                            <img style="width: 30px;" src="@/assets/images/v2/servericon.png" alt="">
                            <h6 class="font-weight-bold text-black mb-0 pl-3">Chọn cấu hình</h6>
                        </div>
                        <div class="card-body p-0">
                            <div class="pricing-table">
                                <section class="pricing-section bg-7 mt-5">
                                    <div class="pricing pricing--norbu col-12 row">
                                        <div v-for="(size, i) in listSize"
                                             :key="i"
                                             class="pricing__item col-4 size-type-item pointer"
                                             :class="{ active: selectedSize === i }"
                                             @click="setActiveSize(i, size)"
                                        >
                                            <h3 class="pricing__title">{{size.name}}</h3>
                                            <div class="pricing__price">
                                                <template v-if="size.sale_price > 0 && size.price !== size.sale_price">
                                                    {{ convertCurrency(size.sale_price) }}
                                                </template>
                                                <template v-if="size.sale_price === 0 && size.price !== size.sale_price">
                                                    {{ convertCurrency(size.price) }}
                                                </template>
                                                <span class="pricing__period">{{getterCurrency.lower}}/ tháng</span>
                                            </div>
                                            <ul class="pricing__feature-list text-center">
                                                <li class="pricing__feature">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        class="feather feather-arrow-right"
                                                    >
                                                        <line x1="5" y1="12" x2="19" y2="12"></line>
                                                        <polyline points="12 5 19 12 12 19"></polyline>
                                                    </svg>
                                                    {{ size.cpu }} VCPU
                                                </li>
                                                <li class="pricing__feature">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        class="feather feather-arrow-right"
                                                    >
                                                        <line x1="5" y1="12" x2="19" y2="12"></line>
                                                        <polyline points="12 5 19 12 12 19"></polyline>
                                                    </svg>
                                                    {{ size.ram / 1000 }}GB RAM
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 layout-spacing layout-top-spacing">
                <div class="statbox panel box box-shadow">
                    <div class="card">
                        <div class="card-header d-flex align-items-center">
                            <img style="width: 30px;" src="@/assets/images/v2/servericon.png" alt="">
                            <h6 class="font-weight-bold text-black mb-0 pl-3">Thêm ổ cứng</h6>
                        </div>
                        <div class="card-body p-0">
                            <div class="row justify-content-between m-0">
                                <div class="card col-12 col-md-3 pointer server-type-item disk-item p-0"
                                     :class="{ active: selectedSSD === 1 }"
                                     @click="setActiveSSD(1, diskFree * 1024, moneyDiskFree)"
                                >
                                    <div class="card-body p-0">
                                        <h5 class="card-title font-weight-bold">{{ convertCurrency(moneyDiskFree) }}đ/tháng</h5>
                                        <p class="title-disk-free title-server-type card-text text-center">
                                            <div class="input-group">
                                                <input
                                                    style="height: 53px;border-bottom: unset;border-radius: unset"
                                                    v-model="diskFree"
                                                    min="10240"
                                                    max="200000"
                                                    type="number"
                                                    class="form-control"
                                                    placeholder="Tuỳ chọn"
                                                    @keyup="changeDisk(diskFree)"
                                                    @blur="changeDisk(diskFree)"
                                                    @change="changeDisk(diskFree)"
                                                />
                                                <span class="input-group-text" style="border-radius: unset" >GB</span>
                                            </div>
                                        </p>
                                    </div>
                                </div>
                                <div class="card col-12 col-md-3 pointer server-type-item disk-item p-0"
                                     :class="{ active: selectedSSD === 2 }"
                                     @click="setActiveSSD(2, 10240, pricePer10GB)"
                                >
                                    <div class="card-body p-0">
                                        <h5 class="card-title font-weight-bold">{{ convertCurrency(pricePer10GB) }} đ/tháng <span class="sub-disk"> 10 GB</span></h5>
                                        <p class="title-disk title-server-type card-text text-center my-3"><b>10 GB</b></p>
                                    </div>
                                </div>
                                <div class="card col-12 col-md-3 pointer server-type-item disk-item p-0 cm-soon"
                                     :class="{ active: selectedSSD === 3 }"
                                     @click="setActiveSSD(3, 20480, pricePer10GB * 2)"
                                >
                                    <div class="card-body p-0">
                                        <h5 class="card-title font-weight-bold">{{ convertCurrency(parseInt(pricePer10GB) * 2) }}đ/tháng <span class="sub-disk"> 20 GB</span></h5>
                                        <p class="title-disk title-server-type card-text text-center my-3"><b>20 GB</b></p>
                                    </div>
                                </div>
                                <div class="card col-12 col-md-3 pointer server-type-item disk-item p-0 cm-soon"
                                     :class="{ active: selectedSSD === 4 }"
                                     @click="setActiveSSD(4, 40960, pricePer10GB * 4)"
                                >
                                    <div class="card-body p-0">
                                        <h5 class="card-title font-weight-bold">{{ convertCurrency(pricePer10GB * 4) }}đ/tháng <span class="sub-disk"> 40 GB</span></h5>
                                        <p class="title-disk title-server-type card-text text-center my-3"><b>40 GB</b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
<!--            <div class="col-lg-12 layout-spacing layout-top-spacing">-->
<!--                <div class="statbox panel box box-shadow">-->
<!--                    <div class="card">-->
<!--                        <div class="card-header d-flex align-items-center">-->
<!--                            <img style="width: 30px;" src="@/assets/images/v2/servericon.png" alt="">-->
<!--                            <h6 class="font-weight-bold text-black mb-0 pl-3">MariaDB Disk</h6>-->
<!--                        </div>-->
<!--                        <div class="card-body p-0">-->
<!--                            <div class="row justify-content-between m-0">-->
<!--                                <div class="card col-12 col-md-3 pointer server-type-item disk-item p-0"-->
<!--                                     :class="{ active: selectedMariaDB === 1 }"-->
<!--                                     @click="setActiveMariaDisk(1, mariaDiskFree * 1024, mariaMoneyDiskFree)"-->
<!--                                >-->
<!--                                    <div class="card-body p-0">-->
<!--                                        <h5 class="card-title font-weight-bold">{{ convertCurrency(mariaMoneyDiskFree) }}đ/tháng</h5>-->
<!--                                        <p class="title-disk-free title-server-type card-text text-center">-->
<!--                                            <div class="input-group">-->
<!--                                                <input-->
<!--                                                    style="height: 53px;border-bottom: unset;border-radius: unset"-->
<!--                                                    v-model="mariaDiskFree"-->
<!--                                                    min="10240"-->
<!--                                                    max="200000"-->
<!--                                                    type="number"-->
<!--                                                    class="form-control"-->
<!--                                                    placeholder="Dung lượng GB"-->
<!--                                                    @keyup="changeMariaDisk(mariaDiskFree)"-->
<!--                                                    @blur="changeMariaDisk(mariaDiskFree)"-->
<!--                                                    @change="changeMariaDisk(mariaDiskFree)"-->
<!--                                                />-->
<!--                                                <span class="input-group-text" style="border-radius: unset" id="basic-addon2">GB</span>-->
<!--                                            </div>-->
<!--                                        </p>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="card col-12 col-md-3 pointer server-type-item disk-item p-0"-->
<!--                                     :class="{ active: selectedMariaDB === 2 }"-->
<!--                                     @click="setActiveMariaDisk(2, 10240, pricePer10GB)"-->
<!--                                >-->
<!--                                    <div class="card-body p-0">-->
<!--                                        <h5 class="card-title font-weight-bold">{{ convertCurrency(pricePer10GB) }} đ/tháng <span class="sub-disk"> 10 GB</span></h5>-->
<!--                                        <p class="title-disk title-server-type card-text text-center my-3"><b>10 GB</b></p>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="card col-12 col-md-3 pointer server-type-item disk-item p-0 cm-soon"-->
<!--                                     :class="{ active: selectedMariaDB === 3 }"-->
<!--                                     @click="setActiveMariaDisk(3, 20480, pricePer10GB * 2)"-->
<!--                                >-->
<!--                                    <div class="card-body p-0">-->
<!--                                        <h5 class="card-title font-weight-bold">{{ convertCurrency(parseInt(pricePer10GB) * 2) }}đ/tháng <span class="sub-disk"> 20 GB</span></h5>-->
<!--                                        <p class="title-disk title-server-type card-text text-center my-3"><b>20 GB</b></p>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="card col-12 col-md-3 pointer server-type-item disk-item p-0 cm-soon"-->
<!--                                     :class="{ active: selectedMariaDB === 4 }"-->
<!--                                     @click="setActiveMariaDisk(4, 40960, pricePer10GB * 4)"-->
<!--                                >-->
<!--                                    <div class="card-body p-0">-->
<!--                                        <h5 class="card-title font-weight-bold">{{ convertCurrency(pricePer10GB * 4) }}đ/tháng <span class="sub-disk"> 40 GB</span></h5>-->
<!--                                        <p class="title-disk title-server-type card-text text-center my-3"><b>40 GB</b></p>-->
<!--                                    </div>-->
<!--                                </div>-->

<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                </div>-->
<!--            </div>-->
            <div class="col-lg-12 layout-spacing layout-top-spacing">
                <div class="statbox panel box box-shadow">
                    <div class="card">
                        <div class="card-header d-flex align-items-center">
                            <img style="width: 30px;" src="@/assets/images/v2/servericon.png" alt="">
                            <h6 class="font-weight-bold text-black mb-0 pl-3">Thông tin Wordpess</h6>
                        </div>
                        <div class="card-body p-0">
                            <form class="p-3">
                                <div class="row mb-4">
                                    <p>Vui lòng nhập đầy đủ thông tin dưới đây</p>
                                    <div class="col-sm-6 mb-2">
                                        <input v-model="user" class="form-control" placeholder="Username" />
                                    </div>
                                    <div class="col-sm-6 mb-2">
                                        <input type="password"
                                               v-model="password" 
                                               class="form-control"
                                               @change="validatePassword()"
                                               @keyup="validatePassword()"
                                               placeholder="Password" />
                                        <small v-if="!pwOK && password !== ''" style="color: red">
                                            Mật khẩu phải chứa ít nhất 8 kí tự bao gồm chữ , số và kí tự đặc biệt !
                                        </small>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 layout-spacing layout-top-spacing">
                <div class="statbox panel box box-shadow">
                    <div class="card">
                        <div class="card-header d-flex align-items-center">
                            <img style="width: 30px;" src="@/assets/images/v2/servericon.png" alt="">
                            <h6 class="font-weight-bold text-black mb-0 pl-3">Số lượng máy chủ</h6>
                        </div>
                        <div class="card-body p-0">
                            <form class="p-3">
                                <div class="row mb-4">
                                    <p>Triển khai đồng thời nhiều máy chủ giúp bạn đạt được trạng thái Zero Downtime của Website</p>
                                    <div class="col-sm-6">
                                        <input type="number" min="1" max="50" class="form-control" v-model="numContainer">
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="sizeObject.cpu !== undefined && sizeObject.ram !== undefined" class="row">
            <div class="box-cart-bottom col-lg-12 layout-spacing layout-top-spacing">
                <div class="statbox panel box box-shadow">
                    <div class="panel-body">
                        <div class="d-flex flex-row align-items-center">
                            <p v-if="sizeObject.cpu !== undefined && sizeObject.ram !== undefined" class="cart-item-bt">VCPU/RAM :
                                <span class="cart-item-vl">{{ sizeObject.cpu }} CORE / {{ sizeObject.ram / 1000 }} GB</span>
                            </p>
                            <p v-if="diskObject.disk !== undefined && diskObject.money !== undefined" 
                               class="cart-item-bt">DISK : 
                                <span class="cart-item-vl"> {{ diskObject.disk / 1024 }} GB</span>
                            </p>
                            <p class="cart-item-bt">Cấu hình : 
                                <span class="cart-item-vl">{{ convertCurrency(calculateContainer()) }}đ/tháng</span> 
                            </p>
                            <p class="cart-item-bt">Số lượng máy chủ : 
                                <span class="cart-item-vl">{{ numContainer }}</span>
                            </p>
                            <p class="cart-item-bt">Chi phí 1 tháng: 
                                <span class="cart-item-vl">{{
                                        sizeObject.price != null && diskObject.money != null
                                            ? convertCurrency(calculateTotalPerMonth())
                                            : 0
                                    }}{{getterCurrency.lower}}</span> 
                            </p>
                            <button
                                :disabled="isValidateFail()"
                                style="height: 40px" 
                                type="button" 
                                class="btn btn-primary" 
                                data-bs-toggle="modal" 
                                data-bs-target="#fadeinModal">
                                Xác nhận tạo thông tin máy chủ
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal animated fadeInDown" id="fadeinModal" tabindex="-1" role="dialog" aria-labelledby="fadeinModal" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="fadeinModalLabel">Xác nhận thông tin</h5>
                        <button type="button" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close" class="btn-close"></button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-hover">
                            <tbody>
                            <tr v-if="regionObject.id !== 0">
                                <th>Quốc gia</th>
                                <td>{{ regionObject.name }}</td>
                            </tr>
                            <tr v-if="sizeObject.cpu !== undefined && sizeObject.ram !== undefined">
                                <th>Cấu hình</th>
                                <td>{{ sizeObject.cpu }} CORE / {{ sizeObject.ram / 1000 }} GB</td>
                            </tr>
                            <tr v-if="diskObject.disk !== undefined">
                                <th>Ổ cứng</th>
                                <td>{{ diskObject.disk / 1024 }} GB</td>
                            </tr>
                            <tr v-if="diskObject.disk !== undefined">
                                <th>MariaDB</th>
                                <td>{{ mariaDbDiskObject.disk / 1024 }} GB</td>
                            </tr>
                            <tr>
                                <th>Thông tin Wordpress</th>
                                <td>{{ user }} / {{ password }}</td>
                            </tr>
                            <tr>
                                <th>Container</th>
                                <td>{{ numContainer }}</td>
                            </tr>
                            <tr>
                                <th>Chi phí/tháng</th>
                                <td>
                                    {{
                                        sizeObject.price != null && diskObject.money != null ? convertCurrency(calculateTotalPerMonth()) : 0
                                    }}
                                    {{ getterCurrency.lower}}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn" data-dismiss="modal" data-bs-dismiss="modal"><i class="flaticon-cancel-12"></i> Huỷ</button>
                        <button type="button" class="btn btn-primary" @click="handleCreateHosting()">Xác Nhận</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import '@/assets/sass/scrollspyNav.scss';

import { NumberSpinner } from 'vue3-number-spinner';

import { useMeta } from '@/composables/use-meta';
useMeta({ title: 'Touchspin' });


import '@/assets/sass/components/cards/card.scss';
import '@/assets/sass/scrollspyNav.scss';
import '@/assets/sass/pricing-table.scss';

import BreadCrumb from "@/views/components/BreadCrumb";
useMeta({ title: 'Hosting' });


</script>

<script>
import router from "@/router";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "HostingCreate",
    data() {
        return {
            listRegion: [],
            selectedRegion: -1,
            regionObject: {
                id: 0
            },
            selectedSize: -1,
            selectedSSD: -1,
            selectedMariaDB: -1,
            listSize: [],
            diskFree: '',
            moneyDiskFree: 0,
            mariaDiskFree: '',
            mariaMoneyDiskFree: 0,
            numContainer: 1,
            sizeObject: {
                id: 0,
                price: 0,
                sale_price: 0
            },
            diskObject: {
                disk: 0,
                money: 0
            },
            mariaDbDiskObject: {
                disk: 0,
                money: 0
            },
            user: '',
            password: '',
            showConfirm: false,
            pricePer10GB: parseInt(process.env.VUE_APP_DISK_PRICE_PER_10GB),
            pwOK: false,
            stepIndex: 2
        }
    },
    created() {
        this.getCpanelRegion({}).then(res => {
            console.log('getCpanelRegion', res)
            this.listRegion = res.data.result.data
        }).catch(e => {
            console.log(e)
        })
        this.getWpSize({}).then(res => {
            this.listSize = res.data.result
        }).catch(e => {
            console.log(e)
        })
    },
    computed:{
        ...mapGetters(['getterMe','getterCurrency'])
    },
    methods: {
        ...mapActions(['getCpanelRegion','getWpSize','createWP']),
        convertCurrency(x) {
            return x.toLocaleString('vi-VN')
        },
        setActiveRegion(number,region) {
            this.selectedRegion = number
            this.regionObject = region
        },
        validatePassword() {
            const pw = this.password
            if (
                /[^a-zA-Z]/.test(pw) &&
                pw.length >= 8 &&
                /\d/.test(pw) &&
                (pw.includes('!') ||
                    pw.includes('@') ||
                    pw.includes('#') ||
                    pw.includes('$') ||
                    pw.includes('%') ||
                    pw.includes('^') ||
                    pw.includes('&') ||
                    pw.includes('*'))
            ) {
                this.pwOK = true
            } else this.pwOK = false
        },
        setActiveSubItemTypeServer(number) {
            this.selectedSubItemTypeServer = number
        },
        setActiveSize(number, size) {
            this.selectedSize = number
            this.sizeObject = size
        },
        setActiveSSD(number,disk, money) {
            this.selectedSSD = number
            this.diskObject = {
                disk,
                money
            }
        },
        setActiveMariaDisk(number, disk , money) {
            this.selectedMariaDB = number
            this.mariaDbDiskObject = {
                disk,
                money
            }
        },
        changeDisk(diskInput) {
            // @ts-ignore: Unreachable code error
            this.moneyDiskFree = diskInput * (this.pricePer10GB / 10)
            this.setActiveSSD(1, diskInput * 1024, this.moneyDiskFree)
        },
        changeMariaDisk(diskInput) {
            // @ts-ignore: Unreachable code error
            this.mariaMoneyDiskFree = diskInput * (this.pricePer10GB / 10)
            this.setActiveMariaDisk(1, diskInput * 1024, this.mariaMoneyDiskFree)
        },
        calculateContainer() {
            let total = 0
            const priceOfSize = this.sizeObject.sale_price !== 0 ? this.sizeObject.sale_price : this.sizeObject.price
            total = priceOfSize * this.numContainer
            return isNaN(total) ? 0 : total
        },
        calculateTotalPerMonth() {
            let totalPerMonth = 0
            const priceOfSize = this.sizeObject.sale_price !== 0 ? this.sizeObject.sale_price : this.sizeObject.price
            const priceOfDisk = this.diskObject.money
            const totalContainer = this.calculateContainer()
            totalPerMonth = priceOfSize + priceOfDisk + totalContainer
            return isNaN(totalPerMonth) ? 0 : totalPerMonth
        },
        handleCreateHosting() {
            const obj = {
                size: this.sizeObject.id,
                region: this.regionObject.id,
                disk: this.diskObject.disk,
                mariadb_disk_size: this.mariaDbDiskObject.disk,
                user: this.user,
                password: this.password,
                replicate: this.numContainer * 1
            }
            this.createWP(obj).then(res => {
                if (res && res.data.message === "success"){
                    
                    // this.$store.dispatch('showNotification',{
                    //     title: `Success !`,
                    //     type: 'success',
                    //     text: `Let's Panel WordPress now !`,
                    // })
                    Pusher.logToConsole = true;
                    var pusher = new Pusher(process.env.VUE_APP_YOUR_PUSHER_APP_KEY, {
                        cluster: 'ap1',
                        authEndpoint: 'https://api-cloud.myepis.cloud/api/v1/auth/pusher',
                    });
                    let userId = JSON.parse(sessionStorage.getItem('me')).id
                    let key = `private-${userId}`;
                    var channel = pusher.subscribe(key);

                    // step 1
                    channel.bind('invoice.paid', function(data) {
                        console.log('invoice.paid', data)
                        var _this = this;
                        _this.stepIndex = 2
                        setTimeout(function (_this){
                            _this.stepIndex = 2
                            setTimeout(function (_this){
                                window.location.href = '/wordpress/' + res.data.result.id
                            },1000)
                        },1000)
                    });
                    // step 1
                    channel.bind('invoice.payment_failed', function(data) {
                        console.log('invoice.payment_failed', data)
                    });
                    // step 1
                    channel.bind('subscribe.create', function(data) {
                        console.log('subscribe.create', data)
                    });
                    // step 2
                    channel.bind('invoice.create', function(data) {
                        console.log('invoice.create', data)
                    });
                    setTimeout(function (){
                       this.$router.push('/wordpress-progress/' + res.data.result.id)
                    },3000)
                }
                else{
                    this.$store.dispatch('showNotification',{
                        title: 'Error !',
                        type: 'error',
                        text: 'Fail to create Wordpress!',
                    })
                }
            }).catch(error => {
                console.log(error)
                this.$store.dispatch('showNotification',{
                    title: 'Error !',
                    type: 'error',
                    text: error.response.data.message,
                })
                return false
            })
        },
        isValidateFail(){
            if (this.regionObject.id == 0) return true
            // Dung lượng ổ cứng tối thiểu tại Việt Nam là 20GB !
            if (this.diskObject.disk < 20480 && this.regionObject.id == 5) return true
            // Dung lượng ổ cứng tối thiểu tại Singapore là 10GB !
            if (this.diskObject.disk < 10240 && this.regionObject.id == 4) return true
            if (this.sizeObject.id == 0) return true
            if (this.diskObject.disk == 0) return true
            if (this.mariaDbDiskObject.disk == 0) return true
            if (!this.pwOK) return true
        },
        binDataPusher(){
            Pusher.logToConsole = true;
            var pusher = new Pusher(process.env.VUE_APP_YOUR_PUSHER_APP_KEY, {
                cluster: 'ap1'
            });
            let userId = JSON.parse(sessionStorage.getItem('me')).id
            let key = `private-${userId}`;
            var channel = pusher.subscribe(key);
            
            // step 1
            channel.bind('subscribe.create', function(data) {
                console.log('subscribe.create', data)
            });
            // step 2
            channel.bind('invoice.create', function(data) {
                console.log('invoice.create', data)
            });
            channel.bind('invoice.create', function(data) {
                console.log('invoice.create', data)
            });
        }
    },
}
</script>
<style scoped>
.touchspin .form-control:disabled,
.touchspin .form-control[readonly] {
    background: #fff !important;
    text-align: center;
    color: #3b3f5c;
    cursor: text !important;
}
.dark .touchspin .form-control:disabled,
.dark .touchspin .form-control[readonly] {
    border: 1px solid #1b2e4b;
    color: #009688 !important;
    background: #1b2e4b !important;
}

.touchspin .btn {
    padding: 6px 12px;
}

.touchspin .vertical-spin {
    width: fit-content;
}

.touchspin .vertical-spin .input-group {
    flex-direction: column;
}

.touchspin .vertical-spin .btn {
    padding: 6px 10px;
    max-width: 45px;
}
.touchspin .vertical-spin .btn.top-minus-btn {
    border-top-right-radius: 4px !important;
    border-bottom-left-radius: 0px;
}
.touchspin .vertical-spin .btn.bottom-plus-btn {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 4px !important;
}

.touchspin .vertical-spin .input-group .form-control {
    width: 45px;
    padding: 8px 0;
}
.cm-soon h5{
    display: flex;
    justify-content: center;
    align-items: center;
}
.cm-soon img{
    width: 70px;
    margin-left: 20px;
}
.pointer{
    cursor: pointer;
}
.server-type-item{
    border-radius: 0;
}
.server-type-item h5{
    padding: 10px;
    text-align: center;
    min-height: 56px;
    border-bottom: 1px solid lightgray;
}

.region-item{
    border-radius: 0;
}
.region-item h5{
    padding: 15px 0;
    text-align: center;
    margin-bottom: 0;
}
.server-type-item.active{
    background-color: #4361ee;
}
.server-type-item.active h5{
    color: white;
    border-bottom: 1px solid unset;
}
.server-type-item.active p{
    color: white;
}
.img-region{
    width: 56px;
    height: 32px;
    margin-left: 20px;
}
.box-cart-bottom{
    position: fixed;
    bottom: 0;
    z-index: 9;
}
.box-cart-bottom .panel-body{
    box-shadow: 5px 10px 10px 5px gray;
}
.cart-item-bt{
    font-weight: bold;
    color: black;
    padding: 12px 80px 0 0;
}
.cart-item-vl{
    font-size: 18px;
    color: #4361ee;
}
.disk-item h5{
    min-height: unset;
    margin-bottom: 0;
}
.sub-disk{
    display: none;
}
.pricing-table .pricing--norbu .pricing__title{
    padding: 0.5rem;
}
.pricing-table .pricing--norbu .pricing__price {
    padding: 20px 0 0 0;
}
.pricing-table .pricing--norbu .pricing__price{
    font-size: 33px;
}
.size-type-item{
    cursor: pointer !important;
}
.size-type-item.active{
    background-color: #4361ee;
    color: white;
}
.size-type-item.active h3{
    color: white !important;
}
.size-type-item.active .pricing__item:hover{
    color: white !important;
}
.pricing-table .pricing--norbu .pricing__item.active:hover {
    color: white;
    border: 1px solid #4361ee;
}
.pricing__title::after {
    background: #4361ee;
}
.ct-wp-create{
    margin-bottom: 150px;
}
.ip-wp-create{
    text-align: center !important;
    color: black !important;
    font-weight: bold !important;
}
input[readonly]{
    text-align: center !important;
    color: black !important;
    font-weight: bold !important;
}
@media (max-width: 1500px) {
    .cart-item-bt{
        font-size: 14px !important;
    }
    .cart-item-vl{
        font-size: 14px;
        color: #4361ee;
    }
    .cart-item-bt{
        padding: 12px 10px 0 0;
    }
    .pricing-table .pricing--norbu .pricing__title {
        font-size: 22px;
    }
    .pricing-table .pricing--norbu .pricing__price{
        font-size: 28px;
        padding: 15px;
    }
    .pricing-table .pricing--norbu .pricing__feature-list {
        padding: 1em 0;
    }
}
@media (max-width: 768px) {
    .sub-disk{
        display: block;
    }
    .title-disk-free{
        display: block !important;
    }
    .title-server-type{
        display: none;
    }
    .server-type-item h5{
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        padding: 12px 10px;
        align-items: center;
    }
    .disk-item h5{
        text-align: center !important;
    }
    .cart-item-bt{
        display: none;
    }
    .box-cart-bottom .panel-body div{
        justify-content: center;
    }
    .ct-wp-create{
        margin-bottom: 100px;
    }
}

/*---------signup-step-------------*/
.bg-color{
    background-color: #333;
}
.signup-step-container{
    padding: 150px 0px;
    padding-bottom: 60px;
}




.wizard .nav-tabs {
    position: relative;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    border-bottom-color: transparent;
}

.wizard > div.wizard-inner {
    position: relative;
    margin-bottom: 50px;
    text-align: center;
}

.connecting-line {
    height: 2px;
    background: #e0e0e0;
    position: absolute;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 15px;
    z-index: 1;
}

.wizard .nav-tabs > li.active > a, .wizard .nav-tabs > li.active > a:hover, .wizard .nav-tabs > li.active > a:focus {
    color: #555555;
    cursor: default;
    border: 0;
    border-bottom-color: transparent;
}

span.round-tab {
    width: 50px;
    height: 50px;
    line-height: 30px;
    display: inline-block;
    border-radius: 50%;
    background: #fff;
    z-index: 2;
    margin-top: -8px;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 18px;
    color: #0e214b;
    padding: 10px;
    font-weight: 500;
    border: 1px solid #ddd;
}
span.round-tab i{
    color:#555555;
}
.wizard li.active span.round-tab {
    background: #0db02b;
    color: #fff;
    border-color: #0db02b;
}
.wizard li.active span.round-tab i{
    color: #5bc0de;
}
.wizard .nav-tabs > li.active > a i{
    color: #0db02b;
}

.wizard .nav-tabs > li {
    width: 0%;
}

.wizard li:after {
    content: " ";
    position: absolute;
    left: 46%;
    opacity: 0;
    margin: 0 auto;
    bottom: 0px;
    border: 5px solid transparent;
    border-bottom-color: red;
    transition: 0.1s ease-in-out;
}



.wizard .nav-tabs > li a {
    width: 30px;
    height: 30px;
    margin: 20px auto;
    border-radius: 100%;
    padding: 0;
    background-color: transparent;
    position: relative;
    top: 0;
}
.wizard .nav-tabs > li a i{
    position: absolute;
    top: -15px;
    font-style: normal;
    white-space: nowrap;
    left: 50%;
    transform: translate(-25%, -100%);
    font-size: 16px;
    font-weight: 700;
    color: #000;
}

.wizard .nav-tabs > li a:hover {
    background: transparent;
}

.wizard .tab-pane {
    position: relative;
    padding-top: 20px;
}


.wizard h3 {
    margin-top: 0;
}
.prev-step,
.next-step{
    font-size: 13px;
    padding: 8px 24px;
    border: none;
    border-radius: 4px;
    margin-top: 30px;
}
.next-step{
    background-color: #0db02b;
}
.skip-btn{
    background-color: #cec12d;
}
.step-head{
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 20px;
}
.term-check{
    font-size: 14px;
    font-weight: 400;
}
.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 40px;
    margin-bottom: 0;
}
.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 40px;
    margin: 0;
    opacity: 0;
}
.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 40px;
    padding: .375rem .75rem;
    font-weight: 400;
    line-height: 2;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}
.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 38px;
    padding: .375rem .75rem;
    line-height: 2;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 .25rem .25rem 0;
}
.footer-link{
    margin-top: 30px;
}
.all-info-container{

}
.list-content{
    margin-bottom: 10px;
}
.list-content a{
    padding: 10px 15px;
    width: 100%;
    display: inline-block;
    background-color: #f5f5f5;
    position: relative;
    color: #565656;
    font-weight: 400;
    border-radius: 4px;
}
.list-content a[aria-expanded="true"] i{
    transform: rotate(180deg);
}
.list-content a i{
    text-align: right;
    position: absolute;
    top: 15px;
    right: 10px;
    transition: 0.5s;
}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: #fdfdfd;
}
.list-box{
    padding: 10px;
}
.signup-logo-header .logo_area{
    width: 200px;
}
.signup-logo-header .nav > li{
    padding: 0;
}
.signup-logo-header .header-flex{
    display: flex;
    justify-content: center;
    align-items: center;
}
.list-inline li{
    display: inline-block;
}
.pull-right{
    float: right;
}
.step-create{
    width: 50%;
    margin: 100px auto;
}

@media (max-width: 767px){
    .sign-content h3{
        font-size: 40px;
    }
    .signup-logo-header .navbar-toggle{
        margin: 0;
        margin-top: 8px;
    }
    .signup-logo-header .logo_area{
        margin-top: 0;
    }
    .signup-logo-header .header-flex{
        display: block;
    }
    .step-create{
        width: 100%;
    }
    span.round-tab {
        width: 35px;
        height: 35px;
        font-size: 14px;
        padding: 2px;
    }
    .wizard .nav-tabs > li a i {
        font-size: 12px;
    }
}

</style>
