<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/">Home</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page"><span>Invoice</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
        <div class="row layout-top-spacing">
            <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
                <div class="panel p-0">
                    <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">
                        <div class="statbox panel box box-shadow">
                            <div class="panel-heading">
                                <div class="d-flex justify-content-between">
                                    <h4>Invoices</h4>
                                </div>
                            </div>
                            <div class="panel-body">
                                <div class="table-responsive">
                                    <table role="table" aria-busy="false" aria-colcount="5" class="table b-table table-bordered" id="__BVID__328">
                                        <thead role="rowgroup" class="" style="background: #eff5ff !important;">
                                        <tr role="row" class="">
                                            <th role="columnheader" scope="col" aria-colindex="1" class=""><div>ID</div></th>
                                            <th role="columnheader" scope="col" aria-colindex="2" class=""><div>Amount Due</div></th>
                                            <th role="columnheader" scope="col" aria-colindex="4" class=""><div>Status</div></th>
                                            <th role="columnheader" scope="col" aria-colindex="4" class=""><div>Invoice PDF</div></th>
                                            <th role="columnheader" scope="col" aria-colindex="4" class=""><div>Invoice URL</div></th>
                                        </tr>
                                        </thead>
                                        <tbody role="rowgroup">
                                            <tr v-if="listInvoice.length"  v-for="(item, i) in listInvoice" :key="id" role="row" class="">
                                            <td aria-colindex="1" role="cell" class="" style="color: #805dca;font-size: 14px">{{ item.id }}</td>
                                            <td aria-colindex="2" role="cell" class="">
                                                {{ convertCurrency(parseInt(item.amount_due)) }} {{getterCurrency.lower}}
                                            </td>
                                            <td v-if="item.amount_due == 0" aria-colindex="2" role="cell" class="">
                                                <span class="badge outline-badge-info">Paid</span>
                                            </td>
                                            <td v-if="item.amount_due > 0" aria-colindex="2" role="cell" class="">
                                                <span class="badge outline-badge-danger">UnPaid</span>
                                            </td>
                                            <td aria-colindex="2" role="cell" class="">
                                                <a :href="item.invoice_pdf" class="text-primary">
                                                    <i class="fa-solid fa-download text-primary"></i>
                                                </a>
                                            </td>
                                            <td aria-colindex="2" role="cell" class="">
                                                <div style="padding-left: 15px" class="td-content text-primary">
                                                    <a target="_blank" :href="item.hosted_invoice_url" class="text-primary">
                                                        VIEW
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                            <tr v-if="!listInvoice.length">
                                                <p class="m-auto text-center py-5">No data !</p>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';

import { useMeta } from '@/composables/use-meta';
useMeta({ title: 'Transaction Log' });
</script>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "InvoiceList",
    data() {
        return {
            listInvoice: [],
        }
    },
    created() {
        this.getInvoices().then(res => {
           this.listInvoice = res.data.result
        }).catch(e => {
            console.log(e)
        })
    },
    computed:{
        ...mapGetters(['getterMe','getterCurrency'])
    },
    methods: {
        ...mapActions(['getWordPress','getInvoices']),
        convertAmountTransaction(x) {
            return parseInt(x).toLocaleString('vi-VN')
        },
        convertCurrency(x) {
            return x.toLocaleString('vi-VN')
        },
        formatCreatedAt(date){
            date = new Date(date);
            let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s;
        }
    },
}
</script>

<style scoped>
.table > tbody > tr > td {
    padding: 13px;
}
</style>
