<template>
    <teleport to="#breadcrumb">
        <ul class="navbar-nav flex-row">
            <li>
                <div class="page-header">
                    <nav class="breadcrumb-one" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link to="/">Home</router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page"><span>{{ moduleName }}</span></li>
                        </ol>
                    </nav>
                </div>
            </li>
        </ul>
    </teleport>
</template>

<script>
export default {
  name: "BreadCrumb",
    props:{
        moduleName: String
    }
}
</script>

<style scoped>

</style>
