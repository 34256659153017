<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/">Home</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page"><span>WordPress</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
        <div class="row layout-top-spacing">
            <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
                <div class="panel p-0">
                    <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">
                        <div class="statbox panel box box-shadow">
                            <div class="panel-heading">
                                <div class="d-flex justify-content-between">
                                    <h4>Thông tin máy chủ</h4>
                                    <div>
                                        <router-link to="/wordpress" tag="button" class="btn btn-primary ml-2">
                                            List Wordpress
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-body icon-tab tabs">
                                <ul class="nav nav-tabs mb-3" id="iconTab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="icon-home-tab" data-bs-toggle="tab" href="#icon-home" role="tab" aria-controls="icon-home" aria-selected="true"
                                        ><i class="fas fa-info-circle"></i>
                                            Thông tin chung</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#icon-profile" role="button" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-history"></i>
                                            Runtime Logs
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="icon-disk-tab" data-bs-toggle="tab" href="#icon-disk" role="tab" aria-controls="icon-disk" aria-selected="false"
                                        >
                                            <i class="fas fa-hdd"></i>
                                            Ổ Cứng</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="icon-disk-maria-tab" data-bs-toggle="tab"
                                           href="#icon-disk-maria" role="tab" aria-controls="icon-disk-maria"
                                           aria-selected="false"
                                        ><i class="fas fa-database"></i>
                                            MariaDB Disk</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="icon-size-tab" data-bs-toggle="tab" href="#icon-size"
                                           role="tab" aria-controls="icon-size" aria-selected="false"
                                        ><i class="fas fa-cog"></i>
                                            Cấu Hình</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="icon-domain-tab" data-bs-toggle="tab" href="#icon-domain" role="tab" aria-controls="icon-domain" aria-selected="false"
                                        ><i class="fas fa-link"></i>
                                            Domain</a
                                        >
                                    </li>
                                </ul>
                                <div v-if="wpDetail != null" class="tab-content" id="iconTabContent-1">
                                    <div class="tab-pane fade show active" id="icon-home" role="tabpanel" aria-labelledby="icon-home-tab">
                                        <div class="table-responsive">
                                            <table role="table" aria-busy="false" aria-colcount="5" class="table b-table table-bordered" id="__BVID__328">
                                                <tr role="row" class="">
                                                    <th role="columnheader" scope="col" aria-colindex="1" class=""><div>Loại máy chủ</div></th>
                                                    <td role="columnheader" scope="col" aria-colindex="2" class=""><div>BASIC</div></td>
                                                </tr>
                                                <tr role="row" class="">
                                                    <th role="columnheader" scope="col" aria-colindex="1" class=""><div>Domain</div></th>
                                                    <td role="columnheader" scope="col" aria-colindex="2" class=""><div>
                                                        <a style="color: #4361ee" target="_blank">
                                                            {{ wpDetail.domain }}
                                                        </a></div></td>
                                                </tr>
                                                <tr role="row" class="">
                                                    <th role="columnheader" scope="col" aria-colindex="1" class=""><div>Bộ xử lý</div></th>
                                                    <td role="columnheader" scope="col" aria-colindex="2" class=""><div>{{ wpDetail.cpu }} VCPU</div></td>
                                                </tr>
                                                <tr role="row" class="">
                                                    <th role="columnheader" scope="col" aria-colindex="1" class=""><div>RAM</div></th>
                                                    <td role="columnheader" scope="col" aria-colindex="2" class=""><div>{{ wpDetail.ram / 1000 }} GB</div></td>
                                                </tr>
                                                <tr role="row" class="">
                                                    <th role="columnheader" scope="col" aria-colindex="1" class=""><div>Disk</div></th>
                                                    <td role="columnheader" scope="col" aria-colindex="2" class=""><div>{{ wpDetail.disk / 1024 }} GB</div></td>
                                                </tr>
                                                <tr role="row" class="">
                                                    <th role="columnheader" scope="col" aria-colindex="1" class=""><div>MariaDB Disk</div></th>
                                                    <td role="columnheader" scope="col" aria-colindex="2" class=""><div>{{ wpDetail.mariadb_disk_size / 1024 }} GB</div></td>
                                                </tr>
                                                <tr role="row" class="">
                                                    <th role="columnheader" scope="col" aria-colindex="1" class=""><div>Region</div></th>
                                                    <td role="columnheader" scope="col" aria-colindex="2" class=""><div>{{ wpDetail.configs.region.name }}</div></td>
                                                </tr>
                                                <tr role="row" class="">
                                                    <th role="columnheader" scope="col" aria-colindex="1" class=""><div>Ngày tạo</div></th>
                                                    <td role="columnheader" scope="col" aria-colindex="2" class=""><div>{{ formatCreatedAt(wpDetail.createdAt) }}</div></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="icon-profile" role="tabpanel">
                                        <div id="list-run-time" class="card-body" style="overflow: scroll; height: 1000px"></div>
                                    </div>
                                    <div class="tab-pane fade" id="icon-disk" role="tabpanel" aria-labelledby="icon-disk-tab">
                                        <table role="table" aria-busy="false" aria-colcount="5" class="table b-table table-bordered">
                                            <tr role="row" class="">
                                                <th role="columnheader" scope="col" aria-colindex="1" class=""><div>Dung lượng</div></th>
                                                <td role="columnheader" scope="col" aria-colindex="2" class=""><div>{{ wpDetail.disk / 1024 }} GB</div></td>
                                            </tr>
                                            <tr role="row" class="">
                                                <th role="columnheader" scope="col" aria-colindex="1" class=""><div>Loại</div></th>
                                                <td role="columnheader" scope="col" aria-colindex="2" class=""><div>SSD</div></td>
                                            </tr>
                                        </table>
                                        <button
                                            style="height: 40px"
                                            type="button"
                                            class="btn btn-primary"
                                            data-bs-toggle="modal"
                                            data-bs-target="#modalUpdateDisk">
                                            Nâng cấp ổ cứng
                                        </button>
                                    </div>
                                    <div class="tab-pane fade" id="icon-disk-maria" role="tabpanel" aria-labelledby="icon-disk-maria-tab">
                                        <table role="table" aria-busy="false" aria-colcount="5" class="table b-table table-bordered">
                                            <tr role="row" class="">
                                                <th role="columnheader" scope="col" aria-colindex="1" class=""><div>Dung lượng MariaDB</div></th>
                                                <td role="columnheader" scope="col" aria-colindex="2" class=""><div>{{ wpDetail.mariadb_disk_size / 1024 }} GB</div></td>
                                            </tr>
                                            <tr role="row" class="">
                                                <th role="columnheader" scope="col" aria-colindex="1" class=""><div>Loại</div></th>
                                                <td role="columnheader" scope="col" aria-colindex="2" class=""><div>SSD</div></td>
                                            </tr>
                                        </table>
                                        <button
                                            style="height: 40px"
                                            type="button"
                                            class="btn btn-primary"
                                            data-bs-toggle="modal"
                                            data-bs-target="#modalUpdateDiskMaria">
                                            Thêm ổ cứng
                                        </button>
                                    </div>
                                    <div class="tab-pane fade" id="icon-size" role="tabpanel" aria-labelledby="icon-size-tab">
                                        <table role="table" aria-busy="false" aria-colcount="5" class="table b-table table-bordered">
                                            <tr role="row" class="">
                                                <th role="columnheader" scope="col" aria-colindex="1" class=""><div>#</div></th>
                                                <th role="columnheader" scope="col" aria-colindex="1" class=""><div>VCPU</div></th>
                                                <th role="columnheader" scope="col" aria-colindex="1" class=""><div>RAM</div></th>
                                                <th role="columnheader" scope="col" aria-colindex="1" class=""><div>Giá / Tháng</div></th>
                                                <th role="columnheader" scope="col" aria-colindex="1"></th>
                                            </tr>
                                            <tr v-for="(size, i) in listSize" :key="i" role="row" class="">
                                                <td role="columnheader" scope="col" aria-colindex="2" class=""><div>{{ i + 1 }}</div></td>
                                                <td role="columnheader" scope="col" aria-colindex="2" class=""><div>{{ size.cpu }} VCPU</div></td>
                                                <td role="columnheader" scope="col" aria-colindex="2" class=""><div>{{ size.ram / 1000 }} GB</div></td>
                                                <td role="columnheader" scope="col" aria-colindex="2" class="">
                                                    <div>
                                                        {{ convertCurrency(size.sale_price != 0 ? size.sale_price : size.price) }}{{getterCurrency.lower}}
                                                    </div>
                                                </td>
                                                <td>
                                                    <button v-if="size.id === wpDetail.size" style="background-color: #805dca" class="btn btn-secondary">Đang có</button>
                                                    <button v-if="size.id !== wpDetail.size"
                                                            style="background-color: #2196f3"
                                                            @click="updateSizeWP(size.id)"
                                                            class="btn btn-info">
                                                        Mua
                                                    </button>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="tab-pane fade" id="icon-domain" role="tabpanel" aria-labelledby="icon-domain-tab">
                                        <p class="mb-1" style="font-size: 16px">
                                            Your server is always available https
                                            <a style="color: #4361ee" target="_blank"><b>{{ wpDetail.domain }}</b></a>
                                        </p>
                                        <div v-if="isFailAddCustomDomain && domainAdd !== ''">
                                            <div class="d-flex align-items-center mt-3">
                                                <p style="font-size: 15px;font-weight: bold;margin-bottom: 0">
                                                    {{ domainAdd }}
                                                </p>
                                                <button  class="btn btn-warning" style="background-color: #e2a03f;margin-left: 20px" @click="addDomainWP()">
                                                    Verify
                                                </button>
                                            </div>
                                            <div class="mt-2">
                                                <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                                                <span class="text-danger">{{ messageAddDomainFail }}</span>
                                            </div>
                                        </div>
                                        <table role="table" aria-busy="false" aria-colcount="5" class="table b-table table-bordered">
                                            <tr v-for="(domain, i) in wpDetail.domains" :key="i" role="row" class="">
                                                <td role="columnheader" scope="col" aria-colindex="2" class=""><div>{{ domain }}</div></td>
                                                <td role="columnheader" scope="col" aria-colindex="2" class=""><div>
                                                    <button class="btn-success">Verified</button>
                                                </div></td>
                                            </tr>
                                        </table>
                                        <button
                                            style="height: 40px"
                                            type="button"
                                            class="btn btn-primary"
                                            @click="isShowAddCustomDomain = true">
                                            Thêm domain
                                        </button>
                                        <div
                                            v-if="isShowAddCustomDomain"
                                            class="d-flex align-items-center justify-content-between w-100 mt-5"
                                        >
                                            <div class="form-group mb-0" style="width: 90%">
                                                <input v-model="domainAdd" type="text" class="form-control" placeholder="Domain Name" />
                                            </div>
                                            <button class="btn btn-primary" @click="addDomainWP()">OK</button>
                                            <button class="btn" @click="isShowAddCustomDomain = false">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal animated fadeInDown" id="modalUpdateDisk" tabindex="-1" role="dialog" aria-labelledby="modalUpdateDisk" aria-hidden="true">
                <div style="max-width: 1200px" class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalUpdateDiskLabel">Chọn dung lượng ổ cứng</h5>
                            <button type="button" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close" class="btn-close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row justify-content-between m-0">
                                <div class="card col-12 col-md-3 pointer server-type-item disk-item p-0"
                                     :class="{ active: selectedSSD === 1 }"
                                     @click="setActiveSSD(1, diskFree * 1024, moneyDiskFree)"
                                >
                                    <div class="card-body p-0">
                                        <h5 class="card-title font-weight-bold">{{ convertCurrency(moneyDiskFree) }}đ/tháng</h5>
                                        <p class="title-disk-free title-server-type card-text text-center">
                                        <div class="input-group">
                                            <input
                                                style="height: 53px;border-bottom: unset;border-radius: unset"
                                                v-model="diskFree"
                                                min="10240"
                                                max="200000"
                                                type="number"
                                                class="form-control"
                                                placeholder="Tuỳ chọn"
                                                @blur="changeDisk(diskFree)"
                                                @change="changeDisk(diskFree)"
                                            />
                                            <span class="input-group-text" style="border-radius: unset" >GB</span>
                                        </div>
                                        </p>
                                    </div>
                                </div>
                                <div class="card col-12 col-md-3 pointer server-type-item disk-item p-0"
                                     :class="{ active: selectedSSD === 2 }"
                                     @click="setActiveSSD(2, 10240, pricePer10GB)"
                                >
                                    <div class="card-body p-0">
                                        <h5 class="card-title font-weight-bold">{{ convertCurrency(pricePer10GB) }} đ/tháng <span class="sub-disk"> 10 GB</span></h5>
                                        <p class="title-disk title-server-type card-text text-center my-3"><b>10 GB</b></p>
                                    </div>
                                </div>
                                <div class="card col-12 col-md-3 pointer server-type-item disk-item p-0 cm-soon"
                                     :class="{ active: selectedSSD === 3 }"
                                     @click="setActiveSSD(3, 20480, pricePer10GB * 2)"
                                >
                                    <div class="card-body p-0">
                                        <h5 class="card-title font-weight-bold">{{ convertCurrency(parseInt(pricePer10GB) * 2) }}đ/tháng <span class="sub-disk"> 20 GB</span></h5>
                                        <p class="title-disk title-server-type card-text text-center my-3"><b>20 GB</b></p>
                                    </div>
                                </div>
                                <div class="card col-12 col-md-3 pointer server-type-item disk-item p-0 cm-soon"
                                     :class="{ active: selectedSSD === 4 }"
                                     @click="setActiveSSD(4, 40960, pricePer10GB * 4)"
                                >
                                    <div class="card-body p-0">
                                        <h5 class="card-title font-weight-bold">{{ convertCurrency(pricePer10GB * 4) }}đ/tháng <span class="sub-disk"> 40 GB</span></h5>
                                        <p class="title-disk title-server-type card-text text-center my-3"><b>40 GB</b></p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn" data-dismiss="modal" data-bs-dismiss="modal"><i class="flaticon-cancel-12"></i> Huỷ</button>
                            <button type="button" class="btn btn-primary" @click="handleUpdateDisk()">Xác Nhận</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal animated fadeInDown" id="modalUpdateDiskMaria" tabindex="-1" role="dialog" aria-labelledby="modalUpdateDisk" aria-hidden="true">
                <div style="max-width: 1200px" class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalUpdateDiskLabelMaria">MariaDb Disk</h5>
                            <button type="button" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close" class="btn-close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row justify-content-between m-0">
                                <div class="card col-12 col-md-3 pointer server-type-item disk-item p-0"
                                     :class="{ active: selectedMariaDB === 1 }"
                                     @click="setActiveMariaDisk(1, mariaDiskFree * 1024, mariaMoneyDiskFree)"
                                >
                                    <div class="card-body p-0">
                                        <h5 class="card-title font-weight-bold">{{ convertCurrency(moneyDiskFree) }}đ/tháng</h5>
                                        <p class="title-disk-free title-server-type card-text text-center">
                                        <div class="input-group">
                                            <input
                                                style="height: 53px;border-bottom: unset;border-radius: unset"
                                                v-model="mariaDiskFree"
                                                min="10240"
                                                max="200000"
                                                type="number"
                                                class="form-control"
                                                placeholder="Tuỳ chọn"
                                                @blur="changeMariaDisk(mariaDiskFree)"
                                                @change="changeMariaDisk(mariaDiskFree)"
                                            />
                                            <span class="input-group-text" style="border-radius: unset" >GB</span>
                                        </div>
                                        </p>
                                    </div>
                                </div>
                                <div class="card col-12 col-md-3 pointer server-type-item disk-item p-0"
                                     :class="{ active: selectedMariaDB === 2 }"
                                     @click="setActiveMariaDisk(2, 10240, pricePer10GB)"
                                >
                                    <div class="card-body p-0">
                                        <h5 class="card-title font-weight-bold">{{ convertCurrency(pricePer10GB) }} đ/tháng <span class="sub-disk"> 10 GB</span></h5>
                                        <p class="title-disk title-server-type card-text text-center my-3"><b>10 GB</b></p>
                                    </div>
                                </div>
                                <div class="card col-12 col-md-3 pointer server-type-item disk-item p-0 cm-soon"
                                     :class="{ active: selectedMariaDB === 3 }"
                                     @click="setActiveMariaDisk(3, 20480, pricePer10GB * 2)"
                                >
                                    <div class="card-body p-0">
                                        <h5 class="card-title font-weight-bold">{{ convertCurrency(parseInt(pricePer10GB) * 2) }}đ/tháng <span class="sub-disk"> 20 GB</span></h5>
                                        <p class="title-disk title-server-type card-text text-center my-3"><b>20 GB</b></p>
                                    </div>
                                </div>
                                <div class="card col-12 col-md-3 pointer server-type-item disk-item p-0 cm-soon"
                                     :class="{ active: selectedMariaDB === 4 }"
                                     @click="setActiveMariaDisk(4, 40960, pricePer10GB * 4)"
                                >
                                    <div class="card-body p-0">
                                        <h5 class="card-title font-weight-bold">{{ convertCurrency(pricePer10GB * 4) }}đ/tháng <span class="sub-disk"> 40 GB</span></h5>
                                        <p class="title-disk title-server-type card-text text-center my-3"><b>40 GB</b></p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn" data-dismiss="modal" data-bs-dismiss="modal"><i class="flaticon-cancel-12"></i> Huỷ</button>
                            <button type="button" class="btn btn-primary" @click="handleUpdateMariaDBDisk()">Xác Nhận</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import $ from 'jquery'
import '@/assets/sass/scrollspyNav.scss';
import '@/assets/sass/components/tabs-accordian/custom-tabs.scss';
import { useMeta } from '@/composables/use-meta';
useMeta({ title: 'WordPress Info' });
</script>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "HostingDetail",
    data() {
        return {
            wpDetail: null,
            selectedSSD: -1,
            selectedMariaDB: -1,
            diskObject: {
                disk: 0,
                money: 0
            },
            mariaDbDiskObject: {
                disk: 0,
                money: 0
            },
            diskFree: '',
            mariaDiskFree: '',
            mariaMoneyDiskFree: 0,
            moneyDiskFree: 0,
            pricePer10GB: parseInt(process.env.VUE_APP_DISK_PRICE_PER_10GB),
            listSize: [],
            isShowAddCustomDomain: false,
            isFailAddCustomDomain: false,
            messageAddDomainFail: '',
            domainAdd: '',
            webSocket: '',
            connection: null
        }
    },
    created() {
        this.getWordPressID(this.$route.params.id).then(res => {
            this.wpDetail = res.data.result
        }).catch(e => {
            console.log(e)
        })
        this.getWpSize({}).then(res => {
            this.listSize = res.data.result
        }).catch(e => {
            console.log(e)
        })
        this.getWpLogSocket(this.$route.params.id).then(res => {
            console.log('response getWpLogSocket', res)
            const url = res.data.result
            console.log('URL ', url)
            this.connectWebSocket(url)
        }).catch(e => {
            console.log(e)
        })
    },
    computed:{
        ...mapGetters(['getterMe','getterCurrency'])
    },
    methods: {
        ...mapActions(['getWordPressID','updateDiskWP','getWpSize','updateSizeWPAPI','updateDomainWP','getWpLogSocket']),
        formatCreatedAt(date){
            date = new Date(date);
            let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s;
        },
        setActiveSSD(number,disk, money) {
            this.selectedSSD = number
            this.diskObject = {
                disk,
                money
            }
        },
        setActiveMariaDisk(number, disk , money) {
            this.selectedMariaDB = number
            this.mariaDbDiskObject = {
                disk,
                money
            }
        },
        changeMariaDisk(diskInput) {
            this.mariaMoneyDiskFree = diskInput * (this.pricePer10GB / 10)
            this.setActiveMariaDisk(1, diskInput * 1024, this.mariaMoneyDiskFree)
        },
        convertCurrency(x) {
            return x.toLocaleString('vi-VN')
        },
        changeDisk(diskInput) {
            this.moneyDiskFree = diskInput * (this.pricePer10GB / 10)
            this.setActiveSSD(1, diskInput * 1024, this.moneyDiskFree)
        },
        handleUpdateDisk() {
            if (this.diskObject.disk < 10240) {
                this.$store.dispatch('showNotification',{
                    title: `Error !`,
                    type: 'error',
                    text: `Dung lượng ổ cứng tối thiếu là 10GB!`,
                })
                return false
            }
            const obj = {
                id: this.$route.params.id,
                name: this.wpDetail.name,
                replicate: this.wpDetail.replicate,
                disk: this.diskObject.disk * 1,
                mariadb_disk_size: this.wpDetail.mariadb_disk_size
            }
            this.updateDiskWP(obj).then(res => {
                console.log('res updateDiskWP', res)
                if (res && res.data.message === "success"){
                    this.$store.dispatch('showNotification',{
                        title: `Success !`,
                        type: 'success',
                        text: `Update Disk Success !`,
                    })
                    setTimeout(function (){
                        window.location.href = '/wordpress/' + this.$route.params.id
                    },1000)
                }
                else{
                    this.$store.dispatch('showNotification',{
                        title: 'Error !',
                        type: 'error',
                        text: `Update Disk Fail !`,
                    })
                }
            }).catch(error => {
                console.log(error)
                this.$store.dispatch('showNotification',{
                    title: 'Error !',
                    type: 'error',
                    text: error.response.data.message,
                })
                return false
            })
        },
        updateSizeWP(id) {
            const obj = {
                id: this.$route.params.id,
                name: this.wpDetail.name,
                size: id
            }
            this.updateSizeWPAPI(obj).then(res => {
                console.log('res updateSizeWPAPI', res)
                if (res && res.data.message === "success"){
                    this.$store.dispatch('showNotification',{
                        title: `Success !`,
                        type: 'success',
                        text: `Update Size Success !`,
                    })
                    setTimeout(function (){
                        window.location.href = '/wordpress/' + this.$route.params.id
                    },1000)
                }
                else{
                    this.$store.dispatch('showNotification',{
                        title: 'Error !',
                        type: 'error',
                        text: `Update Size Fail !`,
                    })
                }
            }).catch(error => {
                console.log(error)
                this.$store.dispatch('showNotification',{
                    title: 'Error !',
                    type: 'error',
                    text: error.response.data.message,
                })
                return false
            })
        },
        handleUpdateMariaDBDisk() {
            if (this.mariaDbDiskObject.disk < 10240) {
                this.$store.dispatch('showNotification',{
                    title: `Error !`,
                    type: 'error',
                    text: `Dung lượng ổ cứng tối thiếu là 10GB!`,
                })
                return false
            }
            const obj = {
                id: this.$route.params.id,
                name: this.wpDetail.name,
                replicate: this.wpDetail.replicate,
                disk: this.wpDetail.disk,
                mariadb_disk_size: this.mariaDbDiskObject.disk * 1,
            }
            this.updateDiskWP(obj).then(res => {
                console.log('res updateDiskWP', res)
                if (res && res.data.message === "success"){
                    this.$store.dispatch('showNotification',{
                        title: `Success !`,
                        type: 'success',
                        text: `Update Disk Success !`,
                    })
                    setTimeout(function (){
                        window.location.href = '/wordpress/' + this.$route.params.id
                    },1000)
                }
                else{
                    this.$store.dispatch('showNotification',{
                        title: 'Error !',
                        type: 'error',
                        text: `Update Disk Fail !`,
                    })
                }
            }).catch(error => {
                console.log(error)
                this.$store.dispatch('showNotification',{
                    title: 'Error !',
                    type: 'error',
                    text: error.response.data.message,
                })
                return false
            })
        },
        addDomainWP() {
            const arr = [this.wpDetail.domains]
            arr.push(this.domainAdd)
            const obj = {
                id: this.$route.params.id,
                name: this.wpDetail.name,
                domains: arr
            }

            this.updateDomainWP(obj).then(res => {
                console.log('res updateDomainWP', res)
                if (res && res.data.message === "success"){
                    this.isShowAddCustomDomain = false
                    this.domainAdd = ''
                    this.messageAddDomainFail = ''
                    this.isFailAddCustomDomain = false
                    this.$store.dispatch('showNotification', {
                        title: `Success !`,
                        type: 'success',
                        text: `Update Disk Success !`,
                    })
                    setTimeout(function () {
                        window.location.href = '/wordpress/' + this.$route.params.id
                    }, 1000)
                }
                else{
                    this.messageAddDomainFail = 'Domain is not verified !'
                    this.isFailAddCustomDomain = true
                    this.$store.dispatch('showNotification', {
                        title: 'Error !',
                        type: 'error',
                        text: 'Update Fail !',
                    })
                }
            }).catch(error => {
                console.log(error)
                this.messageAddDomainFail = error.response.data.message
                this.isFailAddCustomDomain = true
                this.$store.dispatch('showNotification', {
                    title: 'Error !',
                    type: 'error',
                    text: 'Update Fail !',
                })
                return false
            })
        },
        connectWebSocket(url) {
            console.log('Starting connection to WebSocket Server')
            this.connection = new WebSocket(url)
            this.connection.onmessage = function (event) {
                console.log('event', event)
                const str = event.data.split('- -')
                let log = str[1]
                if (str[1] == undefined) {
                    log = str[0]
                }
                $('#list-run-time').append(`<span>- ${log}</span><br /><br />`)
            }
            this.connection.onopen = function (event) {
                console.log('event onopen', event)
                console.log('Successfully connected to the echo websocket server...')
            }
            this.connection.onerror = function (e) {
                $('#list-run-time').html(`<div class="alert alert-light-danger alert-dismissible border-0 mb-4" role="alert">
                                                <strong>Fail !</strong> Can not connect to ${url}
                                                <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">×</button>
                                            </div>
                                             `)
            }
        }
    }
}
</script>

<style scoped>
.cm-soon h5{
    display: flex;
    justify-content: center;
    align-items: center;
}
.cm-soon img{
    width: 70px;
    margin-left: 20px;
}
.pointer{
    cursor: pointer;
}
.server-type-item{
    border-radius: 0;
}
.server-type-item h5{
    padding: 10px;
    text-align: center;
    min-height: 56px;
    border-bottom: 1px solid lightgray;
}

.region-item{
    border-radius: 0;
}
.region-item h5{
    padding: 15px 0;
    text-align: center;
    margin-bottom: 0;
}
.server-type-item.active{
    background-color: #4361ee;
}
.server-type-item.active h5{
    color: white;
    border-bottom: 1px solid unset;
}
.server-type-item.active p{
    color: white;
}
.img-region{
    width: 56px;
    height: 32px;
    margin-left: 20px;
}
.box-cart-bottom{
    position: fixed;
    bottom: 0;
    z-index: 9;
}
.box-cart-bottom .panel-body{
    box-shadow: 5px 10px 10px 5px gray;
}
.cart-item-bt{
    font-weight: bold;
    color: black;
    padding: 12px 80px 0 0;
}
.cart-item-vl{
    font-size: 18px;
    color: #4361ee;
}
.disk-item h5{
    min-height: unset;
    margin-bottom: 0;
}
.sub-disk{
    display: none;
}
.pricing-table .pricing--norbu .pricing__title{
    padding: 0.5rem;
}
.pricing-table .pricing--norbu .pricing__price {
    padding: 20px 0 0 0;
}
.pricing-table .pricing--norbu .pricing__price{
    font-size: 33px;
}
.size-type-item{
    cursor: pointer !important;
}
.size-type-item.active{
    background-color: #4361ee;
    color: white;
}
.size-type-item.active h3{
    color: white !important;
}
.size-type-item.active .pricing__item:hover{
    color: white !important;
}
.pricing-table .pricing--norbu .pricing__item.active:hover {
    color: white;
    border: 1px solid #4361ee;
}
.pricing__title::after {
    background: #4361ee;
}
.ct-wp-create{
    margin-bottom: 150px;
}
.ip-wp-create{
    text-align: center !important;
    color: black !important;
    font-weight: bold !important;
}
input[readonly]{
    text-align: center !important;
    color: black !important;
    font-weight: bold !important;
}
@media (max-width: 768px) {
    .sub-disk{
        display: block;
    }
    .title-disk-free{
        display: block !important;
    }
    .title-server-type{
        display: none;
    }
    .server-type-item h5{
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        padding: 12px 10px;
        align-items: center;
    }
    .disk-item h5{
        text-align: center !important;
    }
    .cart-item-bt{
        display: none;
    }
    .box-cart-bottom .panel-body div{
        justify-content: center;
    }
    .ct-wp-create{
        margin-bottom: 100px;
    }
}
</style>
