<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/">Home</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page"><span>Domain Record</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
        <div v-if="domainDetail != null" class="row layout-top-spacing">
            <div class="col-md-9 col-12 layout-spacing">
                <div class="panel p-0">
                    <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">
                        <div class="statbox panel box box-shadow">
                            <div class="panel-heading">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <h4>Thêm bản ghi DNS</h4>
                                        <p class="mt-3">Tên miền : <b> {{ domainDetail.name }}</b></p>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-body">
                                <div class="card">
                                    <div class="card-header">
                                        <b style="color: #0a0d26">Thêm bản ghi</b>
                                    </div>
                                    <div class="row card-body d-flex">
                                        <div class="mb-4 col-md-5 col-12">
                                            <label class="col-form-label">Name</label>
                                            <div>
                                                <input v-model="name" type="text" class="form-control" placeholder="Dùng @ cho tên miền gốc" />
                                            </div>
                                        </div>
                                        <div class="mb-4 col-md-4 col-12">
                                            <label class="col-form-label">Type</label>
                                            <div>
                                                <select v-model="type" name="" class="form-control" id="">
                                                    <option value="A">A</option>
                                                    <option value="AAAA">AAAA</option>
                                                    <option value="CNAME">CNAME</option>
                                                    <option value="TXT">TXT</option>
                                                    <option value="MX">MX</option>
                                                    <option value="NS">NS</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="mb-4 col-md-2 col-12">
                                            <label class="col-form-label">TTL (second)</label>
                                            <div>
                                                <input v-model="ttl" type="number" class="form-control" placeholder="0" />
                                            </div>
                                        </div>
                                        <div class="mb-4 col-lg-6 col-12">
                                            <label class="col-form-label"> IPV4</label>
                                            <div class="d-flex">
                                                <input v-model="content" type="text" class="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div class="mb-4 col-lg-6 col-12">
                                            <label class="col-form-label">Priority</label>
                                            <div class="d-flex">
                                                <input v-model="priority" type="text" class="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div class="mb-4 col-md-2 col-12">
                                            <label class="col-form-label">Proxied</label>
                                            <div class="d-flex">
                                                <label class="switch s-icons s-outline s-outline-secondary mb-4 me-2">
                                                    <input v-model="proxied" type="checkbox" checked />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-md-3 col-12">
                <div class="panel p-0">
                    <div class="col-lg-12 col-12 layout-spacing">
                        <div class="statbox panel box box-shadow">
                            <div class="panel-heading">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <h4><i class="fa-solid fa-circle-info"></i> Thông tin chung</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-body">
                                <h4 class="font-weight-bold">Tên bản ghi</h4>
                                <p>{{name || '___'}}</p>
                                <h4 class="font-weight-bold">Kiểu</h4>
                                <p>{{type || '___'}}</p>
                                <h4 class="font-weight-bold">TTL</h4>
                                <p>{{ttl || '___'}}</p>
                                <h4 class="font-weight-bold">IP</h4>
                                <p>{{content || '___'}}</p>
                                <button @click="createRecord" class="mt-4 w-100 btn btn-secondary">Xác nhận lưu bản ghi</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';

import { useMeta } from '@/composables/use-meta';
useMeta({ title: 'Domain Record Add' });
</script>
<script>
import {mapActions, mapGetters} from "vuex";
import $ from 'jquery'
export default {
    name: "DomainRecordAdd",
    data() {
        return {
            domainDetail: null,
            name: "",
            type: "A",
            content: "",
            ttl: "",
            priority: "",
            proxied: true,
        }
    },
    mounted() {
        $(".modal-backdrop").remove()
    },
    created() {
        this.getDetailDNS(this.$route.params.id).then(res => {
            this.domainDetail = res.data.result
        }).catch(e => {
            console.log(e)
        })
    },
    computed:{
        ...mapGetters(['getterMe','getterCurrency'])
    },
    methods: {
        ...mapActions(['getDetailDNS','addRecordToDomain']),
        convertAmountTransaction(x) {
            return parseInt(x).toLocaleString('vi-VN')
        },
        convertCurrency(x) {
            return x.toLocaleString('vi-VN')
        },
        formatCreatedAt(date){
            date = new Date(date);
            let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s;
        },
        createRecord(){
            if (this.name === '' || this.content === '' || this.priority === '' || this.ttl === ''){
                this.$store.dispatch('showNotification',{
                    title: 'Error !',
                    type: 'error',
                    text: 'Please input info record !',
                })
                return false;
            }
            let obj = {
                "id" : this.$route.params.id,
                "name": this.name,
                "type": this.type,
                "content": this.content,
                "ttl": this.ttl,
                "priority": parseInt(this.priority),
                "proxied": this.proxied
            }
            this.addRecordToDomain(obj).then(res => {
                console.log('addRecordToDomain',res)
            }).catch(e => {
                console.log(e)
                return false
            })
        }
    },
}
</script>

<style scoped>
.table > tbody > tr > td {
    padding: 13px;
}
.panel{
    border: none !important;
    background-color: unset !important;
}
</style>
